/*
 *
 * LanguageProvider
 *
 * this component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `app/translations`)
 */

import React, { useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import { localstoreUtilites } from "$utils/persistenceData";

export const LanguageProvider = ({ children }) => {
  const locale = localstoreUtilites.getLanguageFromLocalStorage()
    ? localstoreUtilites.getLanguageFromLocalStorage().substr(0, 2)
    : "en";
  const [message, setMessage] = useState(null);
  useEffect(() => {
    if (!message) {
      if (locale === "ko") {
        import("../../translations/ko.json").then((data) => {
          setMessage({ ...data });
        });
      } else if (locale === "ja") {
        import("../../translations/ja.json").then((data) => {
          setMessage({ ...data });
        });
      } else if (locale === "vi") {
        import("../../translations/vi.json").then((data) => {
          setMessage({ ...data });
        });
      } else {
        import("../../translations/en.json").then((data) => {
          setMessage({ ...data });
        });
      }
    }
  }, []);
  return (
    <>
      {message && (
        <IntlProvider locale={locale} key={locale} messages={message}>
          {children}
        </IntlProvider>
      )}
    </>
  );
};
