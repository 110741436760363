import { defineMessages } from "react-intl";

export const scope = "demasterpro.components.Common";
export default defineMessages({
  update: {
    id: `${scope}.update`,
    defaultMessage: `Update`,
  },
  save: {
    id: `${scope}.save`,
    defaultMessage: `Save`,
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: `Cancel`,
  },
  add: {
    id: `${scope}.add`,
    defaultMessage: `Add`,
  },
  edit: {
    id: `${scope}.edit`,
    defaultMessage: `Edit`,
  },
  delete: {
    id: `${scope}.delete`,
    defaultMessage: `Delete`,
  },
  import: {
    id: `${scope}.import`,
    defaultMessage: `Import`,
  },
  back: {
    id: `${scope}.back`,
    defaultMessage: `Back`,
  },
  rowsPerPage: {
    id: `${scope}.rowsPerPage`,
    defaultMessage: `Rows Per Page`,
  },
  noData: {
    id: `${scope}.noData`,
    defaultMessage: `No Data`,
  },
  search: {
    id: `${scope}.search`,
    defaultMessage: `Search`,
  },
  user: {
    id: `${scope}.user`,
    defaultMessage: `User`,
  },
  device: {
    id: `${scope}.device`,
    defaultMessage: `Device`,
  },
  department: {
    id: `${scope}.department`,
    defaultMessage: `Department`,
  },
  startDate: {
    id: `${scope}.startDate`,
    defaultMessage: `Start Date`,
  },
  endDate: {
    id: `${scope}.endDate`,
    defaultMessage: `End Date`,
  },
  image: {
    id: `${scope}.image`,
    defaultMessage: `Image`,
  },
  elevators: {
    id: "demasterpro.containers.App.elevators",
    defaultMessage: ` Elevators`,
  },
  clear: {
    id: `${scope}.clear`,
    defaultMessage: `Clear`,
  },
  poweredOfDeMasterPro: {
    id: `${scope}.poweredOfDeMasterPro`,
    defaultMessage: `Powered by DeMasterPro`,
  },
  meeting: {
    id: `${scope}.meeting`,
    defaultMessage: `Meeting`,
  },
  of: {
    id: `${scope}.of`,
    defaultMessage: `of`,
  },
  invalidDateFormat: {
    id: `${scope}.invalidDateFormat`,
    defaultMessage: `Invalid Date Format`,
  },
  titleSearchUser: {
    id: `${scope}.titleSearchUser`,
    defaultMessage: `Search User`,
  },
  status: {
    id: `${scope}.status`,
    defaultMessage: `Status`,
  },
});
