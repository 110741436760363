import { GLOBAL_STYLE } from "$utils/constants";
import { motion } from "framer-motion";
import React, { useState } from "react";
import { styled } from "styled-components";

export const IconComponent = ({
  icon,
  boxsize = "44px",
  size = "16px",
  color = GLOBAL_STYLE.darkGrayColor,
  onClick,
  disabled,
  bgcolor = "inherit",
  hoverBgColor = GLOBAL_STYLE.darkBlueColor,
}) => {
  const [isHover, setIsHover] = useState(false);
  return (
    <IconBox
      onClick={onClick}
      onHoverStart={() => setIsHover(true)}
      onHoverEnd={() => setIsHover(false)}
      disabled={disabled}
      boxsize={boxsize}
      bgcolor={bgcolor}
      whileHover={bgcolor !== "inherit" && { backgroundColor: hoverBgColor }}
    >
      <StyledIcon size={size} color={disabled ? "lightgray" : color}>
        {icon}
      </StyledIcon>
      {!disabled && bgcolor === "inherit" && (
        // bgcolor가 있다면 굳이 rgba 애니메이션 효과가 필요없다
        <IconBackground
          initial={{ scale: 0 }}
          animate={{ scale: isHover ? 1 : 0 }}
        />
      )}
    </IconBox>
  );
};
const IconBox = styled(motion.div)`
  position: relative;
  padding: 7px;
  cursor: ${(props) => (props.disabled ? "auto" : "pointer")};
  width: ${(props) => props.boxsize};
  height: ${(props) => props.boxsize};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.bgcolor};
  border-radius: 50%;
`;
const StyledIcon = styled.div`
  font-size: ${(props) => props.size};
  color: ${(props) => props.color};
`;
const IconBackground = styled(motion.div)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.1);
`;
