/*
 * Footer Messages
 *
 * This contains all the text for the Footer component.
 */
import { defineMessages } from "react-intl";
import common from "$components/Common/messages";

export const scope = "demasterpro.components.DrawerMenu";
export const menuMessages = defineMessages({
  ...common,
  user: {
    id: `${scope}.User`,
    defaultMessage: `User Management`,
  },
  userManagement: {
    id: `${scope}.userManagement`,
    defaultMessage: `User`,
  },
  department: {
    id: `${scope}.User.department`,
    defaultMessage: `Department`,
  },
  person: {
    id: `${scope}.person`,
    defaultMessage: `Unknown Person`,
  },
  unknownPerson: {
    id: `${scope}.unknownPerson`,
    defaultMessage: `Unknown Person`,
  },
  smartphoneSetting: {
    id: `${scope}.User.smartphoneSetting`,
    defaultMessage: `Smartphone setting`,
  },
  accessControl: {
    id: `${scope}.Access`,
    defaultMessage: `Access management`,
  },
  multiPersonRule: {
    id: `${scope}.Access.multiPersonRule`,
    defaultMessage: `Multi person rule`,
  },
  accessGroup: {
    id: `${scope}.Access.accessGroup`,
    defaultMessage: `Access Group`,
  },
  timeZone: {
    id: `${scope}.Access.timeZone`,
    defaultMessage: `Timezone`,
  },
  holiday: {
    id: `${scope}.Access.holiday`,
    defaultMessage: `Holiday`,
  },
  workingTimeType: {
    id: `${scope}.Access.workingTimeType`,
    defaultMessage: `Working Time Type`,
  },
  menuDoor: {
    id: `${scope}.Door`,
    defaultMessage: `Door Management`,
  },
  deviceSetting: {
    id: `${scope}.Door.deviceSetting`,
    defaultMessage: `Device Setting`,
  },
  deviceManagement: {
    id: `${scope}.Door.deviceManagement`,
    defaultMessage: `Device Management`,
  },
  messageSetting: {
    id: `${scope}.Door.messageSetting`,
    defaultMessage: `Message Setting`,
  },
  building: {
    id: `${scope}.Door.building`,
    defaultMessage: `Building`,
  },
  restaurant: {
    id: `${scope}.Door.restaurant`,
    defaultMessage: `Restaurant`,
  },
  device: {
    id: `${scope}.Door.device`,
    defaultMessage: `Device`,
  },
  wgSetting: {
    id: `${scope}.Door.wgSetting`,
    defaultMessage: `WG setting`,
  },
  report: {
    id: `${scope}.Report`,
    defaultMessage: `Report`,
  },
  monitoring: {
    id: `${scope}.Report.monitoring`,
    defaultMessage: `Monitoring`,
  },
  deviceMonitoring: {
    id: `${scope}.Report.deviceMonitoring`,
    defaultMessage: `Device Monitoring`,
  },
  deviceControl: {
    id: `demasterpro.containers.App.titleDeviceControlRoute`,
    defaultMessage: `Device Control`,
  },
  systemLog: {
    id: `${scope}.Report.systemLog`,
    defaultMessage: `System Log`,
  },
  accessibleDoor: {
    id: `${scope}.Report.accessibleDoor`,
    defaultMessage: `Accessible Door`,
  },
  registeredUser: {
    id: `${scope}.Report.registeredUser`,
    defaultMessage: `Registered User`,
  },
  analysis: {
    id: `${scope}.Report.analysis`,
    defaultMessage: `Analysis`,
  },
  eventLog: {
    id: `${scope}.Report.eventLog`,
    defaultMessage: `Event`,
  },
  timeAttendance: {
    id: `${scope}.Report.timeAttendance`,
    defaultMessage: `Time Attendance`,
  },
  timeAttendanceReport: {
    id: `${scope}.Report.timeAttendanceReport`,
    defaultMessage: `Time Attendance Report`,
  },
  systemManagement: {
    id: `${scope}.SystemManagement`,
    defaultMessage: `System Management`,
  },
  advancedDeviceFunction: {
    id: `${scope}.Configuration.advancedDeviceFunction`,
    defaultMessage: `Advanced Device Function`,
  },
  accountManagement: {
    id: `${scope}.Configuration.accountManagement`,
    defaultMessage: `Account Management`,
  },
  advancedUserFunction: {
    id: `${scope}.Configuration.advancedUserFunction`,
    defaultMessage: `Advanced User Function`,
  },
  setting: {
    id: `${scope}.Configuration.setting`,
    defaultMessage: `Setting`,
  },
  advancedManagement: {
    id: `${scope}.advancedManagement`,
    defaultMessage: `Advanced Management`,
  },
  checkDevice: {
    id: `${scope}.advancedManagement.checkDevice`,
    defaultMessage: `Check Device Setting`,
  },
  checkUser: {
    id: `${scope}.advancedManagement.checkUser`,
    defaultMessage: `Check User Information`,
  },
  emergency: {
    id: `${scope}.advancedManagement.emergency`,
    defaultMessage: `Emergency`,
  },
  deviceUpdate: {
    id: `${scope}.advancedManagement.deviceUpdate`,
    defaultMessage: `Device Update`,
  },
  eventRecovery: {
    id: `${scope}.advancedManagement.eventRecovery`,
    defaultMessage: `Event Recovery`,
  },
  transmitData: {
    id: `${scope}.advancedManagement.transmitData`,
    defaultMessage: `Transmit All Data`,
  },
  aboutVersion: {
    id: `${scope}.advancedManagement.aboutVersion`,
    defaultMessage: `About`,
  },
  messageLog: {
    id: `${scope}.advancedManagement.messageLog`,
    defaultMessage: `Message Log`,
  },
  visitApplicationManagement: {
    id: `${scope}.visitApplicationManagement`,
    defaultMessage: `방문 신청 관리`,
  },
  visitManagement: {
    id: `${scope}.visitManagement`,
    defaultMessage: `방문 신청 관리/조회`,
  },
  visitReport: {
    id: `${scope}.visitReport`,
    defaultMessage: `방문 현황`,
  },
  visitSetting: {
    id: `${scope}.visitSetting`,
    defaultMessage: `Visit Setting`,
  },
  companyManagement: {
    id: `${scope}.companyManagement`,
    defaultMessage: `Company Management`,
  },
  systemAccountManagement: {
    id: `${scope}.systemAccountManagement`,
    defaultMessage: `Account Management`,
  },
  reviewCount: {
    id: `${scope}.reviewCount`,
    defaultMessage: `There are {count} visit applications need to review.`,
  },
  canteenManagement: {
    id: `${scope}.canteenManagement`,
    defaultMessage: `Canteen Management`,
  },
  canteenCornerSetting: {
    id: `${scope}.canteenCornerSetting`,
    defaultMessage: `Corner Setting`,
  },
  canteenUserDiscounts: {
    id: `${scope}.canteenUserDiscounts`,
    defaultMessage: `Exceptional Users`,
  },
  canteenMealTypes: {
    id: `${scope}.canteenMealTypes`,
    defaultMessage: `Meal Types`,
  },
  canteenMealSettings: {
    id: `${scope}.canteenMealSettings`,
    defaultMessage: `Meal Settings`,
  },
  canteenExceptionalMeals: {
    id: `${scope}.canteenExceptionalMeals`,
    defaultMessage: `Exceptional Meals`,
  },
  canteenMealEventLogs: {
    id: `${scope}.canteenMealEventLogs`,
    defaultMessage: `Meal Event Logs`,
  },
  canteenReportSettlement: {
    id: `${scope}.canteenReportSettlement`,
    defaultMessage: `Canteen Settlement`,
  },
  canteenReportByBuilding: {
    id: `${scope}.canteenReportByBuilding`,
    defaultMessage: `Canteen Report By Building`,
  },
  canteenReportByCorner: {
    id: `${scope}.canteenReportByCorner`,
    defaultMessage: `Canteen Report By Corner`,
  },
  roleSetting: {
    id: `${scope}.roleSetting`,
    defaultMessage: `Role Setting`,
  },
  notifications: {
    id: `${scope}.notifications`,
    defaultMessage: `Notifications`,
  },
  canteenDeviceMonitoring: {
    id: `${scope}.canteenDeviceMonitoring`,
    defaultMessage: `Canteen Device Monitoring`,
  },
  canteenMonitoring: {
    id: `${scope}.canteenMonitoring`,
    defaultMessage: `Canteen Monitoring`,
  },
  canteenMealServiceTime: {
    id: `${scope}.canteenMealServiceTime`,
    defaultMessage: "Meal Service Time",
  },
  restaurantDeviceSetting: {
    id: `${scope}.restaurantDeviceSetting`,
    defaultMessage: `Restaurant`,
  },
  dashBoard: {
    id: `${scope}.dashboard`,
    defaultMessage: `Dashboard`,
  },
  canteenSettlement: {
    id: `${scope}.canteenSettlement`,
    defaultMessage: `Settlement`,
  },
  unregisteredDevices: {
    id: `${scope}.unregisteredDevices`,
    defaultMessage: `Unregistered Devices`,
  },
  emailTemplate: {
    id: `${scope}.emailTemplate`,
    defaultMessage: `Email Templates`,
  },
  leaveRequest: {
    id: `${scope}.leaveRequest`,
    defaultMessage: `Leave Requests`,
  },
  leaveManagement: {
    id: `${scope}.leaveManagement`,
    defaultMessage: `Leave Management`,
  },
  timeAttendanceSetting: {
    id: `${scope}.timeAttendanceSetting`,
    defaultMessage: `Time Attendance Setting`,
  },
  cameras: {
    id: `${scope}.cameras`,
    defaultMessage: `Cameras`,
  },
  visitByCamera: {
    id: `${scope}.visitByCamera`,
    defaultMessage: `Add Visit By Camera`,
  },
  eventManagement: {
    id: `${scope}.eventManagement`,
    defaultMessage: `Event Management`,
  },
  meetingManagement: {
    id: `${scope}.meetingManagement`,
    defaultMessage: `Meetings`,
  },
  meetingRoomManagement: {
    id: `${scope}.meetingRoomManagement`,
    defaultMessage: `Meeting Rooms`,
  },
  healthMonitoring: {
    id: `${scope}.healthMonitoring`,
    defaultMessage: `Health Monitoring`,
  },
  carMonitoring: {
    id: "demasterpro.containers.App.carMonitoring",
    defaultMessage: `Car Monitoring`,
  },
  carReport: {
    id: "demasterpro.containers.App.carReport",
    defaultMessage: `Car Report`,
  },
  carManagement: {
    id: `${scope}.carManagement`,
    defaultMessage: `차량 관리`,
  },
  userVehicleManagement: {
    id: `${scope}.userVehicleManagement`,
    defaultMessage: `부대원 차량 관리`,
  },
  unitVehicleManagement: {
    id: `${scope}.unitVehicleManagement`,
    defaultMessage: `부대 차량 관리`,
  },
  vehicleAllocationManagement: {
    id: `${scope}.vehicleAllocationManagement`,
    defaultMessage: `Vehicle Allocation Management`,
  },
  cardIssuing: {
    id: `${scope}.cardIssuing`,
    defaultMessage: `Card Issuing`,
  },
  issuingAccessCard: {
    id: `${scope}.issuingAccessCard`,
    defaultMessage: `Issuing Access Card`,
  },
  issuingVisitCard: {
    id: `${scope}.issuingVisitCard`,
    defaultMessage: `Issuing Visit Card`,
  },
  issuingTempCard: {
    id: `${scope}.issuingTempCard`,
    defaultMessage: `Issuing Temp Card`,
  },
  issuingReport: {
    id: `${scope}.issuingReport`,
    defaultMessage: `Report`,
  },
  issuingSetting: {
    id: `${scope}.issuingSetting`,
    defaultMessage: `Issuance Setting`,
  },
  issuingCardLayouts: {
    id: `${scope}.issuingCardLayouts`,
    defaultMessage: `Card Layouts`,
  },
  userSetting: {
    id: `demasterpro.containers.App.userSettings`,
    defaultMessage: `User Setting`,
  },
  lastLogin: {
    id: `${scope}.lastLogin`,
    defaultMessage: `Last Login`,
  },
  bookManagement: {
    id: `${scope}.bookManagement`,
    defaultMessage: `Book Management`,
  },
  bookArea: {
    id: `${scope}.bookArea`,
    defaultMessage: `Book Area`,
  },
  borrowingManagement: {
    id: `${scope}.borrowingManagement`,
    defaultMessage: `Borrowing Management`,
  },
  bookReport: {
    id: `${scope}.bookReport`,
    defaultMessage: `Book Report`,
  },
  library: {
    id: `${scope}.library`,
    defaultMessage: `Library`,
  },
  userVehicleInReport: {
    id: `${scope}.userVehicleInReport`,
    defaultMessage: `User Vehicle In Report`,
  },
  unitVehicleOutReport: {
    id: `${scope}.unitVehicleOutReport`,
    defaultMessage: `Unit Vehicle Out Report`,
  },
  firmwareVersion: {
    id: `${scope}.firmwareVersion`,
    defaultMessage: `Firmware Management`,
  },
  linkDocument: {
    id: `${scope}.linkDocument`,
    defaultMessage: `Manual`,
  },
  closeMenu: {
    id: `${scope}.closeMenu`,
    defaultMessage: `Hide menu`,
  },
});
