import React from "react";
import { FormattedMessage } from "react-intl";
import { appMessages } from "../../messages";
import { styled } from "styled-components";
import { ButtonComponent } from ".";
import { useRecoilValue } from "recoil";
import { loadingState } from "$utils/atom";

export const OkCancelButtons = ({
  okText = <FormattedMessage {...appMessages.confirm} />,
  okIcon,
  okEvent,
  okDisabled = false,
  cancelText = <FormattedMessage {...appMessages.cancel} />,
  cancelIcon,
  cancelEvent,
  fontSize = "15px",
  height = "45px",
  width = "100px",
  justify = "center",
  style = {},
}) => {
  const isLoading = useRecoilValue(loadingState);
  return (
    <ButtonBox style={style} justify={justify}>
      {okText && (
        <ButtonComponent
          id="transmitOk"
          type="button"
          text={okText}
          icon={okIcon}
          onClick={okEvent}
          width={width}
          height={height}
          fontSize={fontSize}
          disabled={okDisabled || isLoading > 0 || false}
        />
      )}
      <ButtonComponent
        id="transmitCancel"
        type="button"
        contained={false}
        shadow
        text={cancelText}
        icon={cancelIcon}
        onClick={cancelEvent}
        width={width}
        height={height}
        fontSize={fontSize}
        style={{
          color: "gray",
        }}
      />
    </ButtonBox>
  );
};
const ButtonBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.justify};
  gap: 10px;
`;
