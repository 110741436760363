import { ButtonComponent } from "$components/ButtonComponent";
import { faBell, faTable } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { styled } from "styled-components";
import { GLOBAL_STYLE } from "$utils/constants";
import { EachNotification } from "./EachNotification";
import { ToolTipComponent } from "$components/ToolTipComponent";
import { useNavigate } from "react-router-dom";
import { privateRouteMessages } from "$components/PrivateRoute/PrivateRouteWrapper/messages";
import {
  DeleteAllReadNotification,
  PutAllNotificationsAsRead,
} from "$components/PrivateRoute/PrivateRouteWrapper/api";

export const NotificationComponent = ({ data, refetch }) => {
  const [openNotification, setOpenNotification] = useState(false);
  const [onPutAllNotificationsAsRead, { data: result }] =
    PutAllNotificationsAsRead();
  const [onDeleteAllReadNotification, { data: deleteResult }] =
    DeleteAllReadNotification();
  useEffect(() => {
    if (
      (result && result.statusCode) ||
      (deleteResult && deleteResult.statusCode)
    ) {
      refetch();
    }
  }, [result, deleteResult]);
  const navigate = useNavigate();
  return (
    <>
      <NotificationBtn>
        <ButtonComponent
          icon={<FontAwesomeIcon size="lg" icon={faBell} />}
          onClick={(event) => {
            event.stopPropagation();
            setOpenNotification((prev) => !prev);
          }}
          text={
            <span>
              <FormattedMessage {...privateRouteMessages.titleNotifications} />{" "}
              {data?.meta?.totalUnRead > 0 && (
                <span style={{ color: GLOBAL_STYLE.orangeColor }}>
                  +{data?.meta?.totalUnRead}
                </span>
              )}
            </span>
          }
          fontSize="10px"
          height="40px"
          shadow={false}
          justify="end"
          style={{ paddingRight: "15px" }}
        />
      </NotificationBtn>
      {openNotification && (
        <NotificationComponentBox
          className="noScrollbar"
          onClick={(event) => event.stopPropagation()}
          initial={{ opacity: 0 }}
          animate={{
            opacity: openNotification ? 1 : 0,
          }}
        >
          <NotificationContentsBtns>
            <ButtonComponent
              onClick={(event) => {
                event.stopPropagation();
                onPutAllNotificationsAsRead();
              }}
              text={
                <FormattedMessage {...privateRouteMessages.markAllAsRead} />
              }
              fontSize="11px"
              height="40px"
              contained={false}
              width="fit-contents"
              style={{
                border: `1px solid rgba(0,0,0,0.2)`,
              }}
            />
            <ButtonComponent
              onClick={(event) => {
                event.stopPropagation();
                onDeleteAllReadNotification();
              }}
              text={<FormattedMessage {...privateRouteMessages.clearAllRead} />}
              fontSize="11px"
              height="40px"
              contained={false}
              width="fit-contents"
              style={{
                border: `1px solid ${GLOBAL_STYLE.orangeColor}`,
              }}
            />
            <ButtonComponent
              icon={
                <ToolTipComponent
                  text={
                    <FormattedMessage
                      {...privateRouteMessages.viewAllNotification}
                    />
                  }
                >
                  <FontAwesomeIcon size="lg" icon={faTable} />
                </ToolTipComponent>
              }
              onClick={(event) => {
                event.stopPropagation();
                navigate("/notifications");
              }}
              contained={false}
              circle
            />
          </NotificationContentsBtns>
          <NotificationContents>
            {data.data.map((item) => (
              <NotificationContent key={item.id}>
                <EachNotification item={item} refetch={refetch} />
              </NotificationContent>
            ))}
          </NotificationContents>
        </NotificationComponentBox>
      )}
    </>
  );
};
const NotificationBtn = styled.div``;
const NotificationComponentBox = styled(motion.div)`
  position: fixed;
  width: 320px;
  z-index: 2;
  right: 175px;
  top: 92px;
  overflow-y: scroll;
  background-color: ${(props) => props.theme.lightGrayColor};
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
`;
const NotificationContentsBtns = styled.div`
  width: 100%;
  height: 60px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;
const NotificationContents = styled.div`
  width: 100%;
  min-height: 100px;
  max-height: 700px;
`;
const NotificationContent = styled.div`
  width: 100%;
  padding: 15px;
  background-color: rgba(0, 0, 0, 0.02);
`;
