import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { MenuList } from "./constants";
import { TYPE_ACCOUNT } from "$utils/constants";
import { MenuListComponent } from "./MenuListComponent";
import { AnimatePresence } from "framer-motion";
import { useIntl } from "react-intl";
import { mapMenuRouteData } from "./utils";

export const Menus = ({
  navigate,
  accountType,
  countReview,
  setLoadFlag,
  pathname,
}) => {
  const intl = useIntl();
  const [mainMenu, setMainMenu] = useState();
  const [isHover, setIsHover] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState("");
  const browserChangeUrl = () => {
    const map = mapMenuRouteData[pathname];
    if (map) {
      setMainMenu(map.indexParent);
      setSelectedIndex(map.index);
    } else {
      setMainMenu(null);
      setSelectedIndex("");
    }
  };
  const handleListItemClick = (path, parent) => {
    navigate(path);
    setIsHover(false);
    if (path === "/user" || path === "/event") {
      setLoadFlag((prev) => !prev);
    }
  };
  useEffect(() => {
    browserChangeUrl();
  }, [pathname]);
  const menuList = MenuList(accountType);
  const isAdmin = accountType === TYPE_ACCOUNT.systemAdmin;
  const menus = isAdmin ? menuList.adminMenus : menuList.normalMenus;
  const renderMenus = menus.filter((item) => item.isAvailable);
  return (
    <MenuContainer onMouseLeave={() => setIsHover(false)}>
      <AnimatePresence>
        <MainMenus>
          {renderMenus &&
            renderMenus.map((menu, index) => {
              return (
                <MenuListComponent
                  key={menu.id}
                  menu={menu}
                  intl={intl}
                  accountType={accountType}
                  isAdmin={isAdmin}
                  isFirst={index === 0}
                  isLast={index === renderMenus.length - 1}
                  handleListItemClick={handleListItemClick}
                  mainMenu={mainMenu}
                  selectedIndex={selectedIndex}
                  countReview={countReview}
                  isHover={isHover}
                  setIsHover={setIsHover}
                />
              );
            })}
        </MainMenus>
      </AnimatePresence>
    </MenuContainer>
  );
};

const MenuContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
  line-height: 15px;
`;

const MainMenus = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  background-color: ${(props) => props.theme.lightGrayColor};
  z-index: 1;
`;
