import React, { useEffect, useLayoutEffect, useState } from "react";
import { styled } from "styled-components";
import { GetLastLogin, GetLogo, UpdateCountReview } from "../api";
import { WebOverlayHeader } from "./WebOverlayHeader";
import { localstoreUtilites } from "$utils/persistenceData";

export const WebOverlay = ({ accountType, navigate, pathname, children }) => {
  const { data } = GetLastLogin();
  const { data: countReview } = UpdateCountReview();
  const [logo, setLogo] = useState(
    localstoreUtilites.getLogoToSessionStorage()
  );
  const onGetLastLogin = async () => {
    localStorage.setItem("lastLogin", JSON.stringify(data));
  };
  useEffect(() => {
    if (data) {
      onGetLastLogin();
    }
  }, [data]);
  const [loadFlag, setLoadFlag] = useState(false);
  return (
    <WebOverlayContainer>
      {!logo && <GetLogoComponent setLogo={setLogo} />}
      <WebOverlayHeader
        accountType={accountType}
        logo={logo}
        navigate={navigate}
        setLoadFlag={setLoadFlag}
        countReview={countReview}
        pathname={pathname}
      />
      <WebContentsBox>
        <WebContents
          key={String(loadFlag)}
          id="scrolledContents"
          className="noScrollbar"
        >
          {children}
        </WebContents>
      </WebContentsBox>
    </WebOverlayContainer>
  );
};

const GetLogoComponent = ({ setLogo }) => {
  const { data: logoData } = GetLogo();
  useLayoutEffect(() => {
    if (logoData?.logo) {
      setLogo(logoData.logo);
      localstoreUtilites.saveLogoToSessionStorage(logoData.logo);
    }
  }, [logoData]);
  return <></>;
};

const WebOverlayContainer = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: ${(props) => props.theme.lightGrayColor};
`;
const WebContentsBox = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 10px 30px;
  padding-top: 15px;
`;
const WebContents = styled.div`
  overflow-x: hidden;
  width: 100%;
  height: 100%;
`;
