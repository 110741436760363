import React, { useState } from "react";
import {
  InputComponentBox,
  InputComponentOverlay,
  StyledInput,
  StyledText,
} from "./style";
import { Label } from "./Components/Label";
import { Placehoder } from "./Components/Placehoder";
import { CancelBtn } from "./Components/CancelBtn";
import { EXCEPT_SYMBOL_REG_EXP, GLOBAL_STYLE } from "$utils/constants";
import { useDidMountEffect } from "$utils/hooks/useDidMountEffect";

/* 
  register : react-hook-form을 사용하기 위한 매개변수
  reset : reset을 true로 하면 input 값을 초기화하는 아이콘이 생긴다.
  label : label을 true로 하면 placeholder가 absolute로 상단에 border에 위치하게 된다.
  isEmptyBox : 값을 직접 입력하지 않는 경우 true로 한다. onClick 이벤트와 연계해서 사용할 수 있다.
  onClick : input 컴포넌트를 클릭했을 때 실행되는 함수
  onBlur : input에서 blur되면 발동하는 함수
  rest : rest를 통해 input에 property를 추가할 수 있다.
*/

export const InputComponent = ({
  value,
  setValue,
  register,
  icon,
  placeholder,
  width,
  height,
  border = "1px solid rgba(0,0,0,0.1)",
  bgcolor,
  reset,
  disabled = false,
  label = false,
  required = false,
  isEmptyBox = false,
  onClick = () => {},
  onBlur,
  ...rest
}) => {
  const [isFocus, setIsFocus] = useState(false);
  useDidMountEffect(() => {
    if (!isFocus && onBlur) {
      onBlur();
    }
  }, [isFocus]);
  const onChange = (event) => {
    event.target.value = event.target.value.replace(EXCEPT_SYMBOL_REG_EXP, "");
    if (setValue) {
      setValue(event.target.value);
    }
    if (register) {
      register.onChange(event);
    }
  };
  return (
    <InputComponentOverlay width={width} height={height}>
      {label && (
        <Label
          bgcolor={bgcolor}
          required={required}
          disabled={!isEmptyBox && disabled}
          placeholder={placeholder}
        />
      )}
      <InputComponentBox
        $isfocus={isFocus}
        border={border}
        width={width}
        height={height}
        onClick={onClick}
        $isemptybox={isEmptyBox}
        bgcolor={bgcolor}
      >
        {!isEmptyBox ? (
          <StyledInput
            value={value}
            {...register}
            {...rest}
            onFocus={() => setIsFocus(true)}
            onBlur={() => setIsFocus(false)}
            onCopy={() => {
              if (rest?.type === "password") {
                return false;
              }
            }}
            disabled={disabled}
            reset={reset ? "true" : null}
            required={required}
            onChange={onChange}
          />
        ) : (
          <StyledText {...rest} style={{ color: GLOBAL_STYLE.grayColor }}>
            {value}
          </StyledText>
        )}
        {!label && (
          <Placehoder
            isFocus={isFocus}
            value={value}
            disabled={disabled}
            icon={icon}
            placeholder={placeholder}
            required={required}
          />
        )}
        {!disabled && value && reset ? (
          <CancelBtn isFocus={isFocus} value={value} reset={reset} />
        ) : null}
      </InputComponentBox>
    </InputComponentOverlay>
  );
};
