import React, { Suspense } from "react";
import { styled } from "styled-components";
import { useSetRecoilState } from "recoil";
import { loadingState, menuOverlayState, mouseLocaleState } from "$utils/atom";
import { useDidMountEffect } from "$utils/hooks/useDidMountEffect";
import { GLOBAL_STYLE } from "$utils/constants";
import { GridLoader } from "react-spinners";

/* 
  Overlay 컴포넌트. Suspense로 감싸서 children에서 lazy 사용이 가능하다.
  onClick : overlay를 클릭했을 때 실행되는 함수. 일반적으로 close 기능을 한다.
*/

export const Overlay = ({ onClick, open, children, zIndex, style = {} }) => {
  const setIsOverlay = useSetRecoilState(menuOverlayState);
  const setMouseLocale = useSetRecoilState(mouseLocaleState);
  const setLoading = useSetRecoilState(loadingState);
  useDidMountEffect(() => {
    if (!open) {
      setLoading(0);
    }
  }, [open]);
  return (
    <Modal open={open} zindex={zIndex}>
      {/* OverlayBox(overlay)의 자식으로 Box를 둘 경우 Box안에서 mousedown 후 overlay 부분에서 mouseup 했을 때도 overlay가 사라짐. 그러므로 Box와 overlay를 분리*/}
      <OverlayBox onClick={onClick} />
      <Suspense
        fallback={
          <div
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <GridLoader size={11} color={GLOBAL_STYLE.lightGrayColor} />
          </div>
        }
      >
        <Box
          style={style}
          onClick={() => {
            setIsOverlay(false);
            setMouseLocale(null);
          }}
          tabIndex="-1"
        >
          {children}
        </Box>
      </Suspense>
    </Modal>
  );
};

const Modal = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  display: ${(props) => (props.open ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  z-index: ${(props) => Number(props.zindex) || 1300};
`;
const OverlayBox = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  left: 0;
  top: 0;
`;
const Box = styled.div`
  position: absolute;
  margin: 0 auto;
  z-index: 1;
`;
