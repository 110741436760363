import { motion } from "framer-motion";
import { styled } from "styled-components";

export const InputComponentOverlay = styled.div`
  position: relative;
  width: ${(props) => (props.width ? props.width : "100%")};
  height: ${(props) => (props.height ? props.height : "35px")};
`;
export const StyledText = styled(motion.div)`
  position: absolute;
  font-size: 13px;
  left: 5px;
  color: ${(props) => (props.$disabled ? "rgba(0,0,0,0.3)" : "darkgray")};
  display: flex;
  align-items: center;
  gap: 10px;
`;
export const StyledLabel = styled(StyledText)`
  font-size: 11px;
  top: -6px;
  z-index: 3;
  color: ${(props) =>
    props.$disabled
      ? "rgba(0, 0, 0, 0.3)"
      : props.required
      ? props.theme.orangeColor
      : props.theme.darkGrayColor};
  background-color: ${(props) => props.bgcolor || props.theme.lightGrayColor};
  display: flex;
  gap: 3px;
`;
export const InputComponentBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: ${(props) => (props.width ? props.width : "100%")};
  height: ${(props) => (props.height ? props.height : "35px")};
  border: ${(props) =>
    props.$isfocus ? `2px solid ${props.theme.orangeColor}` : props.border};
  border-radius: 3px;
  overflow: hidden;
  cursor: ${(props) => (props.$isemptybox ? "pointer" : "default")};
  background-color: ${(props) =>
    props.bgcolor || props.$isemptybox
      ? "transparent"
      : props.$isfocus
      ? props.theme.whiteColor
      : props.theme.lightGrayColor};
`;
export const StyledInput = styled.input`
  width: 100%;
  height: 100%;
  padding: 0px 5px;
  padding-right: ${(props) => (props.reset ? "30px" : "10px")};
  outline: none;
  border: none;
  background-color: transparent;
  z-index: 1;
  color: ${(props) =>
    props.disabled ? "rgba(0,0,0,0.3)" : props.theme.darkgrayColor};
`;
export const StyledBtn = styled(motion.div)`
  position: absolute;
  right: 6px;
  color: ${(props) => props.theme.grayColor};
  cursor: pointer;
  z-index: 2;
`;

export const CalendarBox = styled.div`
  position: fixed;
  z-index: 30;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: ${(props) => props.theme.lightGrayColor};
  border-radius: 5px;
  padding: 20px 40px;
  .react-calendar {
    width: 350px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 10px;
    color: #3a4856;
  }
  .react-calendar__tile--active {
    background: #e0f2f1;
    color: #3a4856;
  }
  .react-calendar__tile--hasActive {
    background: #e0f2f1;
    color: #3a4856;
  }
  .react-calendar__tile--active:enabled:hover {
    background: #e6e6e6;
  }
  .react-calendar__tile--active:enabled:focus {
    background: #e0f2f1;
    color: #3a4856;
  }
  .react-calendar__tile--now {
    background: #ffcccc;
  }
  .react-calendar__tile--now:enabled:hover {
    background: #e6e6e6;
  }
`;
