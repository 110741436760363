import {
  MENU_NAMES,
  TYPE_ACCOUNT,
  VEHICLE_ALLOCATION_MANAGEMENT,
} from "$utils/constants";
import { localstoreUtilites } from "$utils/persistenceData";
import { isPluginEnabled } from "$utils/plugins";

export const isRenderMenu = (menu) => {
  let rol = false;
  const permissions = localstoreUtilites.getPermissionsFromLocalStorage();
  if (permissions) {
    if (menu === MENU_NAMES.SystemManagement) {
      const { accountType } = localstoreUtilites.getAuthFromLocalStorage();
      rol = accountType === TYPE_ACCOUNT.primaryManager;
    } else if (menu === MENU_NAMES.VehicleManagement) {
      rol =
        (permissions.vehicleManagement &&
          permissions.vehicleManagement.viewVehicle) ||
        (permissions.vehicleAllocation &&
          permissions.vehicleAllocation.viewVehicleAllocation);
    } else if (menu === MENU_NAMES.User) {
      rol = permissions.user && permissions.user.viewUser;
    } else {
      for (let i = 0; i < menu.length; i += 1) {
        const permission = permissions[menu[i]];
        let keyString = "";
        if (menu[i] === "visitManagement") {
          keyString = "viewVisit";
        } else if (menu[i] == "timeAttendanceReport") {
          keyString = "viewAttendance";
        } else {
          keyString = "view".concat(
            menu[i].charAt(0).toUpperCase().concat(menu[i].slice(1))
          );
        }
        rol = permissions[menu[i]] && permission[keyString];
        if (rol) {
          break;
        }
      }
    }
  }
  return rol;
};

export const isRole = (route) => {
  let rol = false;
  const permissions = localstoreUtilites.getPermissionsFromLocalStorage();
  const { accountType } = localstoreUtilites.getAuthFromLocalStorage();
  if (permissions && Object.keys(permissions).length > 0) {
    switch (route) {
      // Dashboard
      case ROUTE.dashBoard:
        rol =
          accountType === TYPE_ACCOUNT.systemAdmin ||
          accountType === TYPE_ACCOUNT.primaryManager ||
          permissions.dashBoard.viewDashBoard;

        break;
      // Monitoring
      case ROUTE.monitoring:
        rol = permissions.monitoring.viewMonitoring;

        break;
      case ROUTE.deviceMonitoring:
        rol = permissions.deviceMonitoring.viewDeviceMonitoring;

        break;
      case ROUTE.carMonitorings:
        rol =
          permissions.monitoring.viewMonitoring &&
          isPluginEnabled("vehiclePlugIn");

        break;
      // Visit
      case ROUTE.visit:
        rol = permissions.visitManagement
          ? permissions.visitManagement.viewVisit
          : false;

        break;
      case ROUTE.visitReport:
        rol = permissions.visitReport
          ? permissions.visitReport.viewVisitReport
          : false;

        break;
      case ROUTE.visitSetting:
        rol = permissions.visitSetting
          ? permissions.visitSetting.editVisitSetting
          : false;

        break;

      // User
      case ROUTE.department:
        rol = permissions.department
          ? permissions.department.viewDepartment
          : false;

        break;
      case ROUTE.user:
        rol = permissions.user ? permissions.user.viewUser : false;

        break;
      case ROUTE.userSetting:
        rol = permissions.user ? permissions.user.updateUserSettingUser : false;

        break;

      // AccessControl
      case ROUTE.accessGroup:
      case ROUTE.registeredUser:
        rol = permissions.accessGroup
          ? permissions.accessGroup.viewAccessGroup
          : false;

        break;
      case ROUTE.timezone:
        rol = permissions.timezone ? permissions.timezone.viewTimezone : false;

        break;
      case ROUTE.holiday:
        rol = permissions.holiday ? permissions.holiday.viewHoliday : false;

        break;
      case ROUTE.accessibleDoor:
        rol = permissions.accessibleDoor
          ? permissions.accessibleDoor.viewAccessibleDoor
          : false;

        break;

      // Time Attendance
      case ROUTE.workingTimeType:
        rol = permissions.workingTime
          ? permissions.workingTime.viewWorkingTime
          : false;

        break;
      case ROUTE.timeAttendanceReport:
        rol = permissions.timeAttendanceReport
          ? permissions.timeAttendanceReport.viewAttendance
          : false;

        break;

      case ROUTE.leaveRequest:
        rol = permissions.leaveRequest
          ? permissions.leaveRequest.viewLeaveRequest
          : false;

        break;

      case ROUTE.leaveManagement:
        rol = permissions.leaveRequest
          ? permissions.leaveRequest.viewLeaveManagementLeaveRequest
          : false;

        break;

      case ROUTE.timeAttendanceSetting:
        rol = permissions.timeAttendanceReport
          ? permissions.timeAttendanceReport.updateAttendanceSettingAttendance
          : false;

        break;

      // Device
      case ROUTE.deviceSetting:
        rol = permissions.deviceSetting
          ? permissions.deviceSetting.viewDeviceSetting
          : false;

        break;
      case ROUTE.messageSetting:
        rol = permissions.deviceMessage
          ? permissions.deviceMessage.editDeviceMessage
          : false;

        break;
      case ROUTE.building:
        rol = permissions.building ? permissions.building.viewBuilding : false;

        break;

      // Report
      case ROUTE.event:
        rol = permissions.report
          ? permissions.report.viewReport || permissions.report.viewAllReport
          : false;

        break;
      case ROUTE.systemLog:
        rol = permissions.systemLog
          ? permissions.systemLog.viewSystemLog
          : false;

        break;

      case ROUTE.carReport:
        rol = isPluginEnabled("vehiclePlugIn");

        break;
      // System Management
      case ROUTE.accountManagement:
      case ROUTE.setting:
      case ROUTE.eventRecovery:
      case ROUTE.emergency:
        rol = accountType === TYPE_ACCOUNT.primaryManager;

        break;
      case ROUTE.canteenCornerSetting:
      case ROUTE.canteenUserDiscounts:
      case ROUTE.canteenMealTypes:
      case ROUTE.canteenMealSettings:
      case ROUTE.canteenExceptionalMeals:
      case ROUTE.canteenMealEventLogs:
      case ROUTE.canteenReportSettlement:
      case ROUTE.canteenReportByBuilding:
      case ROUTE.canteenReportByCorner:
      case ROUTE.canteenMealServiceTime:
        break;
      case ROUTE.notifications:
        rol = accountType === TYPE_ACCOUNT.primaryManager;

        break;
      case ROUTE.canteenDeviceMonitoring:
        rol = accountType === TYPE_ACCOUNT.primaryManager;

        break;
      case ROUTE.vehicleAllocationManagement:
        rol =
          permissions.vehicleAllocation && VEHICLE_ALLOCATION_MANAGEMENT
            ? permissions.vehicleAllocation.viewVehicleAllocation
            : false;

        break;
      case ROUTE.userVehicleManagement:
      case ROUTE.unitVehicleManagement:
        rol = permissions.vehicleManagement
          ? permissions.vehicleManagement.viewVehicle
          : false;

        break;
      case ROUTE.issuingVisitCard:
      case ROUTE.issuingAccessCard:
      case ROUTE.issuingTempCard:
      case ROUTE.issuingReport:
        rol = permissions.cardIssuing
          ? permissions.cardIssuing.viewCardIssuing
          : false;

        break;
      case ROUTE.issuingSetting:
        rol = permissions.cardIssuingSetting
          ? permissions.cardIssuingSetting.viewCardIssuingSetting
          : false;

        break;
      case ROUTE.issuingCardLayouts:
        rol = permissions.cardIssuingLayout
          ? permissions.cardIssuingLayout.viewCardIssuingLayout
          : false;

        break;
      case ROUTE.bookManagement:
      case ROUTE.borrowingManagement:
        rol = permissions.book ? permissions.book.viewBook : false;
        break;
      case ROUTE.bookArea:
      case ROUTE.bookReport:
        rol = permissions.book ? permissions.book.addBook : false;
        break;
      case ROUTE.deviceControl:
        rol = permissions.deviceMonitoring
          ? permissions.deviceMonitoring.sendInstructionDeviceMonitoring
          : false;
        break;
      default:
        rol = true;
    }
  }
  return rol;
};

export const ROUTE = {
  dashBoard: "/",
  // monitoring: '/',
  monitoring: "/monitoring",
  healthMonitoring: "/health-monitoring",
  deviceMonitoring: "/device-monitoring",
  carMonitorings: "/car-monitoring",
  canteenDeviceMonitoring: "/canteen-device-monitoring", // Canteen System
  canteenMonitoring: "/canteen-monitoring", // Canteen System

  // Visit Management
  visit: "/visit",
  visitReport: "/visit/report",
  visitSetting: "/visit/settings",
  visitByCamera: "/visit/camera",

  // User
  department: "/department",
  user: "/user",
  userSetting: "/user/settings",

  // unknown person
  person: "/person",

  // Canteen Management
  canteenMealTypes: "/canteen-mealtypes",
  canteenCornerSetting: "/canteen-corners",
  canteenMealServiceTime: "/canteen-meal-service-time",
  canteenExceptionalMeals: "/canteen-exceptional-meals",
  canteenUserDiscounts: "/canteen-discounts",
  canteenMealSettings: "/canteen-meal-settings",

  // Event Management
  meetings: "/meetings",
  meetingRooms: "/meeting-rooms",

  // Access Control
  accessGroup: "/access-group",
  timezone: "/access-times",
  holiday: "/holiday",
  registeredUser: "/registered-user",

  // Car Management
  userVehicleManagement: "/user-vehicle-management",
  unitVehicleManagement: "/unit-vehicle-management",
  vehicleAllocationManagement: "/vehicle-allocation-management",

  // Attendance
  timeAttendanceReport: "/time-attendance-report",
  workingTimeType: "/workingtime-type",
  workingTimeTypeAdd: "/workingtime-type/add",
  workingTimeTypeEdit: "/workingtime-type/edit",
  leaveRequest: "/leave-request",
  leaveManagement: "/leave-management",
  timeAttendanceSetting: "/time-attendance-setting",

  // Card Issuing
  issuingVisitCard: "/issuing/visit-card",
  issuingAccessCard: "/issuing/access-card",
  issuingTempCard: "/issuing/temp-card",
  issuingReport: "/issuing/report",
  issuingSetting: "/issuing/setting",
  issuingCardLayouts: "/card-layouts",

  // Device Management
  messageSetting: "/message-setting",
  building: "/building",
  restaurant: "/restaurant", // Canteen System
  deviceSetting: "/device-setting",
  deviceSettingAdd: "/device-setting/add",
  deviceSettingEdit: "/device-setting/edit",
  restaurantDeviceSetting: "/restaurant-device-setting", // Canteen System
  cameras: "/cameras",
  elevators: "/elevators",
  elevatorsAdd: "/elevators/add",
  elevatorsEdit: "/elevators/edit",
  unregisteredDevices: "/unregistered-devices", // System Admin

  // Report
  canteenMealEventLogs: "/canteen-meal-event-logs", // Canteen System
  event: "/event",
  carReport: "/car-report",
  systemLog: "/system-log",
  accessibleDoor: "/accessible-door",
  userVehicleInReport: "/user-vehicle-in-report",
  unitVehicleOutReport: "/unit-vehicle-out-report",

  // Canteen Settlement
  canteenReportSettlement: "/canteen-report-settlement",
  canteenReportByBuilding: "/canteen-report-by-building",
  canteenReportByCorner: "/canteen-report-by-corner",

  // Library Management
  bookManagement: "/book",
  borrowingManagement: "/book-borrow",
  bookArea: "/book-area",
  bookReport: "/book-report",

  // System Management
  company: "/company", // System Admin
  systemAccountManagement: "/system-account", // System Admin
  accountManagement: "/account-management",
  roleSetting: "/role-setting",
  eventRecovery: "/event-recovery",
  setting: "/setting",
  emergency: "/emergency",
  emailTemplate: "/email-templates",

  // Notification
  notifications: "/notifications",

  // Advanced Management
  checkDevice: "/check-device",
  checkUser: "/check-user",
  deviceUpdate: "/device-update",
  firmwareVersion: "/firmware-version",
  transmitData: "/transmit-data",
  aboutVersion: "/about",

  analysis: "/analysis",
  // messageLog: '/message-log',
};

export const mapMenuRouteData = {
  // monitoring
  [ROUTE.dashBoard]: {
    index: ROUTE.dashBoard,
    indexParent: "dashBoard",
  },
  [ROUTE.monitoring]: {
    index: ROUTE.monitoring,
    indexParent: "monitoring",
  },
  [ROUTE.healthMonitoring]: {
    index: ROUTE.healthMonitoring,
    indexParent: "monitoring",
  },
  [ROUTE.deviceMonitoring]: {
    index: ROUTE.deviceMonitoring,
    indexParent: "monitoring",
  },
  [ROUTE.canteenDeviceMonitoring]: {
    index: ROUTE.canteenDeviceMonitoring,
    indexParent: "canteenMonitoring",
  },
  [ROUTE.canteenMonitoring]: {
    index: ROUTE.canteenMonitoring,
    indexParent: "canteenMonitoring",
  },
  [ROUTE.carMonitorings]: {
    index: ROUTE.carMonitorings,
    indexParent: "monitoring",
  },

  // user
  [ROUTE.department]: { index: ROUTE.department, indexParent: "user" },
  [ROUTE.user]: { index: ROUTE.user, indexParent: "user" },
  [ROUTE.userSetting]: { index: ROUTE.userSetting, indexParent: "user" },
  // access
  [ROUTE.accessGroup]: {
    index: ROUTE.accessGroup,
    indexParent: "accessControl",
  },
  [ROUTE.registeredUser]: {
    index: ROUTE.registeredUser,
    indexParent: "accessControl",
  },
  [ROUTE.timezone]: { index: ROUTE.timezone, indexParent: "accessControl" },
  [ROUTE.holiday]: { index: ROUTE.holiday, indexParent: "accessControl" },

  // unknown person
  [ROUTE.person]: { index: ROUTE.person, indexParent: "unknownPerson" },

  // door
  [ROUTE.deviceSetting]: {
    index: ROUTE.deviceSetting,
    indexParent: "deviceSetting",
  },
  [ROUTE.restaurantDeviceSetting]: {
    index: ROUTE.restaurantDeviceSetting,
    indexParent: "deviceSetting",
  },
  [ROUTE.messageSetting]: {
    index: ROUTE.messageSetting,
    indexParent: "deviceSetting",
  },
  [ROUTE.building]: { index: ROUTE.building, indexParent: "deviceSetting" },
  [ROUTE.restaurant]: { index: ROUTE.restaurant, indexParent: "deviceSetting" },
  [ROUTE.unregisteredDevices]: {
    index: ROUTE.unregisteredDevices,
    indexParent: "deviceSetting",
  },
  [ROUTE.cameras]: {
    index: ROUTE.cameras,
    indexParent: "deviceSetting",
  },
  [ROUTE.elevators]: {
    index: ROUTE.elevators,
    indexParent: "deviceSetting",
  },
  // report
  [ROUTE.event]: { index: ROUTE.event, indexParent: "report" },
  [ROUTE.carReport]: { index: ROUTE.carReport, indexParent: "report" },
  [ROUTE.systemLog]: { index: ROUTE.systemLog, indexParent: "report" }, // SeA
  [ROUTE.accessibleDoor]: {
    index: ROUTE.accessibleDoor,
    indexParent: "accessControl",
  },
  [ROUTE.analysis]: { index: ROUTE.analysis, indexParent: "report" },
  [ROUTE.userVehicleInReport]: {
    index: ROUTE.userVehicleInReport,
    indexParent: "report",
  },
  [ROUTE.unitVehicleOutReport]: {
    index: ROUTE.unitVehicleOutReport,
    indexParent: "report",
  },

  // system
  [ROUTE.accountManagement]: {
    index: ROUTE.accountManagement,
    indexParent: "systemManagement",
  }, // SeA
  [ROUTE.setting]: {
    index: ROUTE.setting,
    indexParent: "systemManagement",
  }, // SeA
  [ROUTE.emergency]: {
    index: ROUTE.emergency,
    indexParent: "systemManagement",
  },
  [ROUTE.eventRecovery]: {
    index: ROUTE.eventRecovery,
    indexParent: "systemManagement",
  },
  [ROUTE.roleSetting]: {
    index: ROUTE.roleSetting,
    indexParent: "systemManagement",
  },
  [ROUTE.emailTemplate]: {
    index: ROUTE.emailTemplate,
    indexParent: "systemManagement",
  },
  //  advanced
  [ROUTE.checkDevice]: {
    index: ROUTE.checkDevice,
    indexParent: "advancedManagement",
  },
  [ROUTE.checkUser]: {
    index: ROUTE.checkUser,
    indexParent: "advancedManagement",
  },
  [ROUTE.deviceUpdate]: {
    index: ROUTE.deviceUpdate,
    indexParent: "advancedManagement",
  },
  [ROUTE.firmwareVersion]: {
    index: ROUTE.firmwareVersion,
    indexParent: "advancedManagement",
  },
  [ROUTE.transmitData]: {
    index: ROUTE.transmitData,
    indexParent: "advancedManagement",
  },
  // [ROUTE.messageLog]: { index: 23, indexParent: 'advancedManagement' },
  [ROUTE.aboutVersion]: {
    index: ROUTE.aboutVersion,
    indexParent: "advancedManagement",
  },

  // visitApplicationManagement
  [ROUTE.visit]: {
    index: ROUTE.visit,
    indexParent: "visitApplicationManagement",
  },
  [ROUTE.visitReport]: {
    index: ROUTE.visitReport,
    indexParent: "visitApplicationManagement",
  },
  [ROUTE.visitSetting]: {
    index: ROUTE.visitSetting,
    indexParent: "visitApplicationManagement",
  },
  [ROUTE.visitByCamera]: {
    index: ROUTE.visitByCamera,
    indexParent: "visitApplicationManagement",
  },
  // System Admin
  [ROUTE.company]: {
    index: ROUTE.company,
    indexParent: "adminSystemManagement",
  },
  [ROUTE.systemAccountManagement]: {
    index: ROUTE.systemAccountManagement,
    indexParent: "adminSystemManagement",
  },

  // timeAttendance
  [ROUTE.timeAttendanceReport]: {
    index: ROUTE.timeAttendanceReport,
    indexParent: "timeAttendance",
  },
  [ROUTE.workingTimeType]: {
    index: ROUTE.workingTimeType,
    indexParent: "timeAttendance",
  },
  [ROUTE.leaveRequest]: {
    index: ROUTE.leaveRequest,
    indexParent: "timeAttendance",
  },
  [ROUTE.leaveManagement]: {
    index: ROUTE.leaveManagement,
    indexParent: "timeAttendance",
  },
  [ROUTE.timeAttendanceSetting]: {
    index: ROUTE.timeAttendanceSetting,
    indexParent: "timeAttendance",
  },
  // canteenManagement
  [ROUTE.canteenCornerSetting]: {
    index: ROUTE.canteenCornerSetting,
    indexParent: "canteenManagement",
  },
  [ROUTE.canteenUserDiscounts]: {
    index: ROUTE.canteenUserDiscounts,
    indexParent: "canteenManagement",
  },
  [ROUTE.canteenMealSettings]: {
    index: ROUTE.canteenMealSettings,
    indexParent: "canteenManagement",
  },
  [ROUTE.canteenExceptionalMeals]: {
    index: ROUTE.canteenExceptionalMeals,
    indexParent: "canteenManagement",
  },
  [ROUTE.canteenMealEventLogs]: {
    index: ROUTE.canteenMealEventLogs,
    indexParent: "report",
  },
  [ROUTE.canteenReportSettlement]: {
    index: ROUTE.canteenReportSettlement,
    indexParent: "canteenSettlement",
  },
  [ROUTE.canteenReportByBuilding]: {
    index: ROUTE.canteenReportByBuilding,
    indexParent: "canteenSettlement",
  },
  [ROUTE.canteenReportByCorner]: {
    index: ROUTE.canteenReportByCorner,
    indexParent: "canteenSettlement",
  },
  [ROUTE.notifications]: {
    index: ROUTE.notifications,
    indexParent: "notifications",
  },
  [ROUTE.canteenMealServiceTime]: {
    index: ROUTE.canteenMealServiceTime,
    indexParent: "canteenManagement",
  },

  // eventManagement
  [ROUTE.meetings]: {
    index: ROUTE.meetings,
    indexParent: "eventManagement",
  },
  [ROUTE.meetingRooms]: {
    index: ROUTE.meetingRooms,
    indexParent: "eventManagement",
  },
  [ROUTE.issuingAccessCard]: {
    index: ROUTE.issuingAccessCard,
    indexParent: "cardIssuing",
  },
  [ROUTE.issuingVisitCard]: {
    index: ROUTE.issuingVisitCard,
    indexParent: "cardIssuing",
  },
  [ROUTE.issuingTempCard]: {
    index: ROUTE.issuingTempCard,
    indexParent: "cardIssuing",
  },
  [ROUTE.issuingReport]: {
    index: ROUTE.issuingReport,
    indexParent: "cardIssuing",
  },
  [ROUTE.issuingSetting]: {
    index: ROUTE.issuingSetting,
    indexParent: "cardIssuing",
  },
  [ROUTE.issuingCardLayouts]: {
    index: ROUTE.issuingCardLayouts,
    indexParent: "cardIssuing",
  },

  // bookManagement
  [ROUTE.bookManagement]: {
    index: ROUTE.bookManagement,
    indexParent: "bookManagement",
  },
  [ROUTE.borrowingManagement]: {
    index: ROUTE.borrowingManagement,
    indexParent: "bookManagement",
  },
  [ROUTE.bookArea]: {
    index: ROUTE.bookArea,
    indexParent: "bookManagement",
  },
  [ROUTE.bookReport]: {
    index: ROUTE.bookReport,
    indexParent: "bookManagement",
  },
};
