import React, { useState } from "react";
import { styled } from "styled-components";
import { FormattedMessage } from "react-intl";
import messages from "routes/Login/messages";
import { IconComponent } from "$components/IconComponent";
import { motion } from "framer-motion";
import { GLOBAL_STYLE } from "$utils/constants";
import { faBriefcase, faDoorOpen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const EachCompany = ({ company, onClick, selected }) => {
  const [isHover, setIsHover] = useState(false);
  return (
    <EachCompanyBox
      onHoverStart={() => setIsHover(true)}
      onHoverEnd={() => setIsHover(false)}
      initial={{ backgroundColor: "inherit", color: "lightgray" }}
      animate={{
        backgroundColor: isHover
          ? "rgba(0,0,0,0.1)"
          : selected
          ? "rgba(0,0,0,0.05)"
          : "inherit",
        color: isHover ? "white" : "lightgray",
      }}
      onClick={() => onClick(company.id)}
    >
      <EachBox>
        <IconBox color="primary">
          {!company.logo ? (
            <FontAwesomeIcon size="2x" icon={faBriefcase} />
          ) : (
            <CompanyIcon alt="company logo" src={company.logo} />
          )}
        </IconBox>
        <CompanyName>{company.name}</CompanyName>
      </EachBox>
      <EachBox style={{ gap: 0, paddingRight: "25px" }}>
        <StyledSelectText>
          <FormattedMessage {...messages.selectToLogin} />
          <UnderLine
            initial={{ x: 130 }}
            animate={{ x: isHover ? -10 : 130 }}
            transition={{
              ease: "linear",
              duration: 0.2,
            }}
          />
        </StyledSelectText>
        <DoorIconBox>
          <IconComponent
            icon={<FontAwesomeIcon icon={faDoorOpen} />}
            color={GLOBAL_STYLE.grayColor}
            size="25px"
          />
        </DoorIconBox>
      </EachBox>
    </EachCompanyBox>
  );
};
const EachCompanyBox = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  font-size: 12px;
  padding: 8px;
  border-radius: 10px;
`;
const EachBox = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  position: relative;
`;
const IconBox = styled.div`
  width: 50px;
  height: 50px;
  background-color: lightgray;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  overflow: hidden;
`;
const CompanyIcon = styled.img`
  width: 50px;
  height: 50px;
`;
const CompanyName = styled.span`
  color: ${(props) => props.theme.darkGrayColor};
  font-weight: bold;
  font-size: 15px;
`;
const StyledSelectText = styled.div`
  padding: 5px;
  padding-right: 15px;
  padding-top: 8px;
  position: relative;
  overflow: hidden;
  z-index: 1;
`;
const UnderLine = styled(motion.div)`
  position: absolute;
  width: calc(100% + 7px);
  height: 22px;
  background-color: ${(props) => props.theme.grayColor};
  right: -10px;
  bottom: 0;
  z-index: -1;
  border-radius: 2px;
`;
const DoorIconBox = styled.div`
  position: absolute;
  right: -5px;
  z-index: 2;
`;
