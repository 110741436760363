import React from "react";
import { ThemeProvider, styled } from "styled-components";
import { localstoreUtilites } from "$utils/persistenceData";
import PrivateRouter from "routes/PrivateRouter";
import PublicRouter from "routes/PublicRouter";
import SnackbarComponent from "$components/SnackbarComponent";
import { useSetRecoilState } from "recoil";
import { menuOverlayState, mouseLocaleState } from "$utils/atom";
import LoadingComponent from "$components/LoadingComponent";
import { RenderToolTip } from "$components/ToolTipComponent/RenderToolTip";
import { SnackbarProvider } from "notistack";
import { QueryClient, QueryClientProvider } from "react-query";
import { GLOBAL_STYLE, PAGE_TITLE } from "$utils/constants";
import { Helmet } from "react-helmet";
import "./App.css";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // window focus 설정
      retry: 1,
    },
  },
});

function App() {
  const auth = localstoreUtilites.getAuthFromLocalStorage();
  const permission = localstoreUtilites.getPermissionsFromLocalStorage();
  const setIsOverlay = useSetRecoilState(menuOverlayState);
  const setMouseLocale = useSetRecoilState(mouseLocaleState);
  return (
    <>
      <Helmet titleTemplate={`%s - ${PAGE_TITLE}`} defaultTitle="DeMasterPro">
        <meta name="description" content="DeMasterPro" />
      </Helmet>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={GLOBAL_STYLE}>
          <AppContainer
            onClick={() => {
              setIsOverlay(false);
              setMouseLocale(null);
            }}
          >
            {auth.isAuthed && permission && auth.token ? (
              <PrivateRouter
                isAuthed={auth.isAuthed}
                accountType={auth.accountType}
                token={auth.token}
                permission={permission}
              />
            ) : (
              <PublicRouter
                isAuthed={auth.isAuthed}
                auth={auth}
                permission={permission}
              />
            )}
            <LoadingComponent />
            <SnackbarProvider
              maxSnack={4}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <SnackbarComponent />
            </SnackbarProvider>
            <RenderToolTip />
          </AppContainer>
        </ThemeProvider>
      </QueryClientProvider>
    </>
  );
}

export default App;

const AppContainer = styled.div`
  margin: 0 auto;
  display: flex;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  flex-direction: column;
  position: absolute;
  right: 0;
  left: 0;
  color: ${(props) => props.theme.darkGrayColor};
`;
