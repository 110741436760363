import { STATUS_COMPLETED } from "$components/TableComponent/constants";
import {
  countReviewState,
  errorState,
  progressState,
  responseMsgState,
} from "$utils/atom";
import { MQTT_TYPE } from "$utils/constants";
import WebSocketMqtt from "$utils/mqtt";
import { isAdmin, localstoreUtilites } from "$utils/persistenceData";
import { useEffect } from "react";
import { useSetRecoilState } from "recoil";

const configWs = localstoreUtilites.getConfigWSFromLocalStorage();
const localUsername = localstoreUtilites.getUsernameFromLocalStorage();

export const useHandleNotiAndProgressMqtt = () => {
  const setProgress = useSetRecoilState(progressState);
  const setCountReview = useSetRecoilState(countReviewState);
  const setErrorState = useSetRecoilState(errorState);
  const setResponseState = useSetRecoilState(responseMsgState);
  useEffect(() => {
    if (!("Notification" in window)) {
      console.log("Browser does not support desktop notification");
    } else {
      Notification.requestPermission();
    }
    if (configWs && !window.DeMaster_Mqtt_Client) {
      window.DeMaster_Mqtt_Client = new WebSocketMqtt()
        .init()
        .addReciveMessageHandler(handleReciveMessageMqtt)
        .onReciveMessage()
        .setTopics([
          MQTT_TYPE.PROCESS_PROGRESSBAR.topic,
          isAdmin()
            ? MQTT_TYPE.NOTIFICATION.topic
            : MQTT_TYPE.NOTIFICATION.topic.concat(
                `/${localstoreUtilites.getCompanycodeFromLocalStorage()}`
              ),
        ])
        .connect(
          (context, topicsIsSubscribed) => {
            context.subscribeTopics(topicsIsSubscribed);
          },
          (message) => {
            setErrorState(`connect failed: ${message}`);
          }
        );
    }
  }, []);
  const handleReciveMessageMqtt = (message) => {
    const messageQueue = JSON.parse(message.payloadString);
    const { sender } = messageQueue;
    if (sender === localUsername) {
      if (messageQueue.type === MQTT_TYPE.NOTIFICATION.type) {
        if (
          messageQueue.data.notificationType === "NOTIFICATION_COUNT_REVIEW"
        ) {
          const countReview = JSON.parse(messageQueue.data.message);
          setCountReview(countReview);
        } else {
          // Show snack bar message by comparing the sender in the message with the currently logged in account.
          setResponseState({
            message: messageQueue.data.message,
            variant: messageQueue.data.messageType,
            persist: messageQueue.data.keep,
          });
          // Push Desktop Notification in case
          if (messageQueue.data.messageType === "info") {
            const notification = new Notification(messageQueue.data.message);
            if (messageQueue.data.relatedUrl) {
              const url = `${window.location.protocol}//${window.location.host}${messageQueue.data.relatedUrl}`;
              notification.onclick = () => window.open(url);
            }
          }
        }
      }
      if (messageQueue.type === MQTT_TYPE.PROCESS_PROGRESSBAR.type) {
        if (
          messageQueue.data.progress === "-1.00" ||
          messageQueue.data.progress === "100.00" ||
          messageQueue.data.progress !== "100.00"
        ) {
          setProgress((prev) => ({
            ...prev,
            [messageQueue.data.processId]: {
              progress:
                messageQueue.data.progress === "-1.00"
                  ? prev[messageQueue.data.processId].progress
                  : Number(messageQueue.data.progress),
              status:
                messageQueue.data.progress === "100" ||
                messageQueue.data.progress === "100.00"
                  ? STATUS_COMPLETED
                  : messageQueue.data.name,
            },
          }));
        }
      }
    }
  };
};
