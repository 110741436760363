import { atom } from "recoil";
import { localstoreUtilites } from "./persistenceData";

// ----- snackbar
export const errorState = atom({
  key: "errorState",
  default: null,
});
export const responseMsgState = atom({
  key: "responseMsgState",
  default: null,
});
export const alertMsgState = atom({
  key: "alertMsgState",
  default: null,
});
export const countReviewState = atom({
  key: "countReviewState",
  default: null,
});

// ----- overlay
export const menuOverlayState = atom({
  key: "menuOverlayState",
  default: false,
});
export const loadingState = atom({
  key: "loadingState",
  default: 0,
});
export const mouseLocaleState = atom({
  key: "mouseLocaleState",
  default: null,
});
export const tooltipMouseLocaleState = atom({
  key: "tooltipMouseLocaleState",
  default: null,
});
export const TooltipTextState = atom({
  key: "TooltipTextState",
  default: null,
});
export const TempTooltipTextState = atom({
  key: "TempTooltipTextState",
  default: null,
});
// ----- PageCommon
export const pageModalAtom = atom({
  key: "pageModalAtom",
  default: null,
});
export const pageNameAtom = atom({
  key: "pageNameAtom",
  default: null,
});
export const typeOfApiState = atom({
  key: "typeOfApiState",
  default: null,
});
const tableHeight = localstoreUtilites.getTableHeightFromLocalStorage();

export const tableHeightState = atom({
  key: "tableHeightState",
  default: Number(tableHeight) || 44,
});

// ----- Table Component
export const selectedRowState = atom({
  key: "selectedRowState",
  default: [],
});
export const tablePageState = atom({
  key: "tablePageState",
  default: 1,
});
export const tableRowsPerPageState = atom({
  key: "tableRowsPerPageState",
  default: 25,
});
export const tableSortState = atom({
  key: "tableSortState",
  default: null,
});
export const tableSortDirectState = atom({
  key: "tableSortDirectState",
  default: "desc",
});
export const tableFilterState = atom({
  key: "tableFilterState",
  default: {},
});

export const progressState = atom({
  // progressbar 데이터
  key: "progressState",
  default: {},
});
export const progressBtnModalState = atom({
  // progress 기능 관련 modal open 및 종류 여부
  key: "progressBtnModalState",
  default: undefined,
});
export const updatedRowState = atom({
  // row 데이터 변경을 위한 state
  key: "updatedRowState",
  default: {},
});
export const ButtonRowWidthState = atom({
  // 행의 버튼 컴포넌트 width state (헤더의 width 설정)
  key: "ButtonRowWidthState",
  default: null,
});
export const contextRowState = atom({
  key: "contextRowState",
  default: null,
});

export const defaultRowsPerPageState = atom({
  key: "defaultRowsPerPageState",
  default: 10,
});

// ------- sticky btn box state
export const fixedMenuState = atom({
  key: "fixedMenuState",
  default: false,
});
// ------ Assing Table Component
export const assignSelectedRowState = atom({
  key: "assignSelectedRowState",
  default: [],
});
export const assignTablePageState = atom({
  key: "assignTablePageState",
  default: 1,
});
export const assignTableRowsPerPageState = atom({
  key: "assignTableRowsPerPageState",
  default: 25,
});
export const assignTableSortState = atom({
  key: "assignTableSortState",
  default: null,
});
export const assignTableSortDirectState = atom({
  key: "assignTableSortDirectState",
  default: "asc",
});
export const assignTableFilterState = atom({
  key: "assignTableFilterState",
  default: {},
});
// ------ Modal(Sub) Table Component
export const subSelectedRowState = atom({
  key: "subSelectedRowState",
  default: [],
});
export const subTablePageState = atom({
  key: "subTablePageState",
  default: 1,
});
export const subTableRowsPerPageState = atom({
  key: "subTableRowsPerPageState",
  default: 25,
});
export const subTableSortState = atom({
  key: "subTableSortState",
  default: null,
});
export const subTableSortDirectState = atom({
  key: "subTableSortDirectState",
  default: "asc",
});
export const subTableFilterState = atom({
  key: "subTableFilterState",
  default: {},
});

// ------ Modal(Third) Table Component
export const thirdSelectedRowState = atom({
  key: "thirdSelectedRowState",
  default: [],
});
export const thirdTablePageState = atom({
  key: "thirdTablePageState",
  default: 1,
});
export const thirdTableRowsPerPageState = atom({
  key: "thirdTableRowsPerPageState",
  default: 25,
});
export const thirdTableSortState = atom({
  key: "thirdTableSortState",
  default: null,
});
export const thirdTableSortDirectState = atom({
  key: "thirdTableSortDirectState",
  default: "asc",
});
export const thirdTableFilterState = atom({
  key: "thirdTableFilterState",
  default: {},
});
// ------ Monitoring Page's State
export const eventLogDatasState = atom({
  key: "eventLogDatasState",
  default: [],
});
export const alarmLogDatasState = atom({
  key: "alarmLogDatasState",
  default: [],
});
export const userLogDatasState = atom({
  key: "userLogDatasState",
  default: [],
});
// ------- For Building Page door
export const buildingDataState = atom({
  key: "buildingDataState",
  default: [],
});

// ------- For approve multi visitor
export const selectedVisitorsState = atom({
  key: "selectedVisitorsState",
  default: [],
});
