import { motion } from "framer-motion";
import { styled } from "styled-components";
import { useHandleTooltipLocale } from "./useHandleTooltipLocale";

export const ToolTipComponent = ({ children, text, style }) => {
  const [handleStartHover, handleEndHover] = useHandleTooltipLocale(
    text,
    style
  );
  return (
    <ToolTipComponentBox
      onHoverStart={(e) => handleStartHover(e)}
      onHoverEnd={handleEndHover}
    >
      {children}
    </ToolTipComponentBox>
  );
};

const ToolTipComponentBox = styled(motion.div)`
  position: relative;
  left: 0;
  top: 0;
  overflow: hidden;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
