import { GLOBAL_STYLE } from "$utils/constants";
import { motion } from "framer-motion";
import React, { useState } from "react";
import { styled } from "styled-components";

/* 
  bgcolor > contained 우선순위로 버튼의 배경색 스타일이 적용됨.
  contained나 circle의 유무로 버튼의 default 사이즈가 변경됨.
  shadow : 버튼의 그림자 사용 유무. contained 유무로 default가 적용됨.
  justify : text, icon 위치 배정
  gap : icon과 text의 gap
  rest : 버튼에 style이나 type등의 property를 커스텀으로 지정 가능함.
*/

export const ButtonComponent = ({
  text,
  onClick,
  disabled,
  contained = true,
  icon,
  circle = false,
  width = circle ? "35px" : "100%",
  fontSize = contained ? "15px" : "12px",
  height = circle ? "35px" : contained ? "45px" : "30px",
  shadow = contained,
  // text styles
  justify = "center",
  gap = "10px",
  textUnderline = false,
  bgcolor,
  borderRadius,
  ...rest
}) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <StyledButton
      {...rest}
      disabled={disabled}
      $circle={circle}
      onClick={!disabled ? onClick : undefined}
      width={width}
      height={height}
      shadow={
        shadow
          ? contained
            ? GLOBAL_STYLE.shadow
            : GLOBAL_STYLE.lightShadow
          : "none"
      }
      fontSize={fontSize}
      $contained={contained}
      onHoverStart={() => {
        if (!disabled) {
          setIsHovered(true);
        }
      }}
      onHoverEnd={() => setIsHovered(false)}
      $borderRadius={borderRadius}
      transition={{ border: { duration: 0.05 } }}
      animate={
        bgcolor
          ? { backgroundColor: bgcolor }
          : contained
          ? {
              backgroundColor: isHovered
                ? GLOBAL_STYLE.darkGrayColor
                : GLOBAL_STYLE.grayColor,
            }
          : {
              backgroundColor: isHovered ? "rgba(0,0,0,0.1)" : "inherit",
            }
      }
    >
      <TabComponent
        whileTap={
          !disabled
            ? {
                border: circle
                  ? `1px solid ${GLOBAL_STYLE.orangeColor}`
                  : `3px solid ${GLOBAL_STYLE.orangeColor}`,
              }
            : {}
        }
        whileHover={bgcolor ? { border: `2px solid rgba(0,0,0,0.3)` } : {}}
        $circle={circle}
        $borderRadius={borderRadius}
        transition={{ duration: 0.05 }}
      />
      <ButtonText gap={gap} justify={justify}>
        {icon && icon}
        {text && (
          <span style={textUnderline ? { textDecoration: "underline" } : {}}>
            {text}
          </span>
        )}
      </ButtonText>
    </StyledButton>
  );
};
const StyledButton = styled(motion.button)`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  font-size: ${(props) => props.fontSize};
  color: ${(props) =>
    props.$contained ? props.theme.lightGrayColor : props.theme.grayColor};
  font-family: "NotoSans";
  border-radius: ${(props) =>
    props.$borderRadius || (props.$circle ? "50%" : "3px")};
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  box-shadow: ${(props) => props.shadow};
  position: relative;
  outline: none;
  border: none;
  opacity: ${(props) => (props.disabled ? "0.3" : "1")};
`;
const TabComponent = styled(motion.div)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: ${(props) =>
    props.$borderRadius || (props.$circle ? "50%" : "3px")};
`;
const ButtonText = styled.span`
  display: flex;
  gap: ${(props) => props.gap};
  justify-content: ${(props) => props.justify};
  align-items: center;
`;
