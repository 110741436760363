import { FormattedMessage } from "react-intl";
import { endOfDay, format, parse, startOfDay } from "date-fns";
import { localstoreUtilites } from "$utils/persistenceData";
import {
  DATE_FORMAT,
  DATE_FORMAT_WITH_HYPHEN,
  DATE_TIME_FORMAT,
  DATE_TIME_FORMAT_WITH_PIPE,
  DateTimeFormatDefault,
  LANGUAGE,
} from "$utils/constants";
import { getTimezoneOffset, utcToZonedTime } from "date-fns-tz";
import { privateRouteMessages } from "$components/PrivateRoute/PrivateRouteWrapper/messages";

const lang = localstoreUtilites.getLanguageFromLocalStorage();
const timezone = localstoreUtilites.getAccountTzFromLocalStorage();

export function formatDateToSend(date) {
  // "dd.MM.YYYY HH:MM:SS"
  const newDate = new Date(
    new Date(date).setHours(
      new Date(date).getHours() - new Date(date).getTimezoneOffset() / -60
    )
  );
  let dateStr =
    ("00" + newDate.getDate()).slice(-2) +
    "." +
    ("00" + (newDate.getMonth() + 1)).slice(-2) +
    "." +
    newDate.getFullYear() +
    " " +
    ("00" + newDate.getHours()).slice(-2) +
    ":" +
    ("00" + newDate.getMinutes()).slice(-2) +
    ":" +
    ("00" + newDate.getSeconds()).slice(-2);
  return dateStr;
}

export function convertDefaultDateTimeToString(date) {
  return format(date, DateTimeFormatDefault);
}

export function convertDateToUtc(date) {
  // -> To UTC Time
  try {
    const newDate = new Date(date);

    return newDate.setMilliseconds(-getTimezoneOffset(timezone));
  } catch (error) {
    return date;
  }
}
// 12.13.2024 00:00:00 (KO) -> 12.12.2024 15:00:00 (UTC)
export const convertToUtcWithDefaultTime = (date) => {
  if (date) {
    return convertDefaultDateTimeToString(convertDateToUtc(date));
  } else {
    return null;
  }
};
export const countCreatedDuration = (date) => {
  const createdDate = new Date(date);
  const timeDiff = Math.floor((new Date() - createdDate) / 1000);
  if (timeDiff < 60) {
    return (
      <>
        {timeDiff}
        <FormattedMessage {...privateRouteMessages.secondsAgo} />
      </>
    );
  }
  const minuteDiff = Math.floor(timeDiff / 60);
  if (minuteDiff < 60) {
    return (
      <>
        {minuteDiff}
        <FormattedMessage {...privateRouteMessages.minutesAgo} />
      </>
    );
  }
  const hourDiff = Math.floor(minuteDiff / 60);
  if (hourDiff < 24) {
    return (
      <>
        {hourDiff}
        <FormattedMessage {...privateRouteMessages.hoursAgo} />
      </>
    );
  }
  const days = Math.floor(hourDiff / 24);
  if (days <= 10) {
    return (
      <>
        {days}
        <FormattedMessage {...privateRouteMessages.daysAgo} />
      </>
    );
  }
  return format(new Date(date), "yyyy.MM.dd");
};

export const reformatTime = (date) => {
  if (date) {
    return format(date, "HH:mm:ss");
  }
  return undefined;
};

export const reformatDate = (date, dateFormat, withHypen) => {
  try {
    if (date) {
      return format(
        date,
        withHypen
          ? dateFormat
            ? DATE_FORMAT_WITH_HYPHEN[dateFormat]
            : lang
            ? DATE_FORMAT_WITH_HYPHEN[lang]
            : DATE_FORMAT_WITH_HYPHEN[LANGUAGE.ENGLISH]
          : dateFormat
          ? DATE_FORMAT[dateFormat]
          : lang
          ? DATE_FORMAT[lang]
          : DATE_FORMAT[LANGUAGE.ENGLISH]
      );
    }
    return "";
  } catch {
    return "undefined";
  }
};

export const reformatDateTime = (date, dateFormat) => {
  try {
    if (date) {
      return format(
        date,
        dateFormat
          ? DATE_TIME_FORMAT[dateFormat]
          : lang
          ? DATE_TIME_FORMAT[lang]
          : DATE_TIME_FORMAT["en-US"]
      );
    }
    return "";
  } catch {
    return "undefined";
  }
};

export const startDate = reformatDate(
  new Date(startOfDay(new Date())),
  LANGUAGE.ENGLISH
);
export const toDate = reformatDate(new Date(), LANGUAGE.ENGLISH);

export const startDateWithTime = reformatDateTime(
  new Date(startOfDay(new Date())),
  LANGUAGE.ENGLISH
);
export const toDateWithTime = reformatDateTime(
  new Date(endOfDay(new Date())),
  LANGUAGE.ENGLISH
);

export const reformatDateTimeWithPipe = (date) => {
  if (date) {
    return format(
      date,
      lang
        ? DATE_TIME_FORMAT_WITH_PIPE[lang]
        : DATE_TIME_FORMAT_WITH_PIPE["en-US"]
    );
  }
  return undefined;
};
function parseDateTime(str, haveTime) {
  let date = str.split(/[.:' '-]+/);
  if (haveTime) {
    return (
      date[2] +
      "-" +
      date[1] +
      "-" +
      date[0] +
      " " +
      date[3] +
      ":" +
      date[4] +
      ":" +
      date[5]
    );
  } else {
    return date[2] + "-" + date[0] + "-" + date[1];
  }
}
export function convertShowDate(data) {
  try {
    const langData = lang || "en-US";
    const dateStr = parseDateTime(data, true);
    return format(
      utcToZonedTime(new Date(dateStr), timezone),
      DATE_TIME_FORMAT[langData]
    );
  } catch {
    return data;
  }
}
export function convertShowDateTime(data) {
  try {
    const lang = localstoreUtilites.getLanguageFromLocalStorage() || "en-US";
    const timeZone = localstoreUtilites.getAccountTzFromLocalStorage();
    if (!data) return data;
    let dateStr = parse(data, DateTimeFormatDefault, new Date());
    const date = format(new Date(dateStr), "yyyy-MM-dd'T'HH:mm:ss'Z'", {
      timeZone: "UTC",
    });
    const zonedDate = utcToZonedTime(date, timeZone);
    return format(zonedDate, DATE_TIME_FORMAT[lang]);
  } catch {
    return data;
  }
}
export const date2Time = (date, hour12 = true) => {
  if (date) {
    return date
      .toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12,
      })
      .replace(/[^ -~]/g, "");
  }
  return null;
};

// date -> string
export const date2String = (date, separater = ".") => {
  // Sun Dec 30 2091 23:59:59 GMT+0900 (한국 표준시) -> 2091.12.30
  if (date) {
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    const formattedDate = `${year}${separater}${
      month < 10 ? 0 + month.toString() : month
    }${separater}${day < 10 ? 0 + day.toString() : day}`;
    return formattedDate;
    /* switch (
      lang ||
      localstoreUtilites.getLanguageFromLocalStorage() ||
      LANGUAGE.ENGLISH
    ) {
      case LANGUAGE.JAPANESE:
      case LANGUAGE.KOREA: {
        // YYYY.MM.DD
        const formattedDate = `${year}${separater}${
          month < 10 ? 0 + month.toString() : month
        }${separater}${day < 10 ? 0 + day.toString() : day}`;
        return formattedDate;
      }
      case LANGUAGE.VIETNAMESE: {
        // DD.MM.YYYY
        const formattedDate = `${day < 10 ? 0 + day.toString() : day}.${
          month < 10 ? 0 + month.toString() : month
        }.${year}`;
        return formattedDate;
      }
      case LANGUAGE.ENGLISH: // MM.DD.YYYY
      default: {
        const formattedDate = `${month < 10 ? 0 + month.toString() : month}.${
          day < 10 ? 0 + day.toString() : day
        }.${year}`;
        return formattedDate;
      }
    } */
  }
  return null;
};

/* 
convertDateUtcToUserTime(
  convertStringDefaultToDateTime("30.12.2091 14:59:59")
)
-----> Sun Dec 30 2091 23:59:59 GMT+0900 (한국 표준시)
*/
export function convertDateUtcToUserTime(date) {
  try {
    const temp = date;
    return new Date(
      temp.setMilliseconds(getTimezoneOffset(timezone || "en-US"))
    );
  } catch (error) {
    return date;
  }
}
export function convertStringDefaultToDateTime(textDate, textFormat) {
  if (!textFormat) {
    textFormat = DateTimeFormatDefault;
  }
  return parse(textDate, textFormat, new Date());
}

export const reformatStringDate = (
  sourceStringDate,
  sourcePattern,
  targetPattern
) =>
  sourceStringDate
    ? sourceStringDate.replace(sourcePattern, targetPattern)
    : null;
export const getTargetPattern = () => {
  switch (
    localstoreUtilites.getLanguageFromLocalStorage() ||
    LANGUAGE.ENGLISH
  ) {
    case LANGUAGE.JAPANESE:
      return "$2.$1.$3";
    case LANGUAGE.KOREA:
      return "$3.$2.$1";
    default:
      return "$2.$1.$3";
  }
};
export const changeUnixToTime = (unixTime) => {
  const date = new Date(parseInt(unixTime, 10) * 1000);
  const zonedDate = utcToZonedTime(date, timezone);
  const time = format(
    zonedDate,
    lang ? DATE_TIME_FORMAT[lang] : DATE_TIME_FORMAT["en-US"]
  );
  return time;
};
export const changeTimeToUnix = (time) => {
  return Math.floor(new Date(time).getTime() / 1000);
};
export function addOneYear(date) {
  const dateCopy = new Date(date);
  dateCopy.setFullYear(dateCopy.getFullYear() + 10);
  return dateCopy;
}

export function secondsToString(seconds) {
  const numyears = Math.floor(seconds / 31536000);
  const numdays = Math.floor((seconds % 31536000) / 86400);
  const numhours = Math.floor(((seconds % 31536000) % 86400) / 3600);
  const numminutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60);
  // const numseconds = (((seconds % 31536000) % 86400) % 3600) % 60;
  const years = !numyears === 0 ? `${numyears} years` : "";
  const days = !(numyears === 0 && numdays === 0) ? `${numdays} days` : "";

  return `${years} ${days} ${numhours} hours ${numminutes} minutes`;
}
