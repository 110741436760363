import React, { useCallback, useEffect } from "react";
import { menuOverlayState } from "$utils/atom";
import { useRecoilState } from "recoil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { ClickSelectBox, Icon, IconBox } from "./style";

export const ClickSelect = ({
  id,
  value,
  options,
  setIsFocus,
  setPosition,
  disabled,
  fontSize,
  isFocus,
  darkTheme,
  setIsDown,
  isMulti,
}) => {
  const [isOverlay, setIsOverlay] = useRecoilState(menuOverlayState);
  useEffect(() => {
    if (!isOverlay) {
      setIsFocus(false);
      setPosition(null);
    }
    if (isOverlay && isOverlay.toString() === id) {
      setIsFocus(true);
    } else {
      setIsFocus(false);
      setPosition(null);
    }
  }, [isOverlay]);
  const selected = useCallback(() => {
    return isMulti
      ? value?.length > 0
        ? options
            .filter((item) => value.includes(item.id))
            .map((item) => item.name)
        : ""
      : options.find((item) => String(item.id) === String(value))?.name;
  }, [value, options])();
  return (
    <ClickSelectBox
      $disabled={disabled}
      $darktheme={darkTheme}
      $isfocus={isFocus}
      fontSize={fontSize}
      onClick={(event) => {
        if (!disabled) {
          event.stopPropagation();
          if (!isFocus) {
            setIsOverlay(id);
          }
          const target = event.currentTarget.getBoundingClientRect();
          setPosition({
            x: target.left,
            y: target.top,
          });
          setIsDown(event.clientY < 400 ? true : false);
          setIsFocus((prev) => !prev);
        }
      }}
    >
      {isMulti
        ? selected &&
          selected.map((item, index) => (
            <span key={index} style={{ marginRight: "5px" }}>
              {item}
              {selected.length !== index + 1 && `, `}
            </span>
          ))
        : selected}
      <IconBox>
        <Icon animate={{ rotate: isFocus ? 180 : 0 }}>
          <FontAwesomeIcon icon={faCaretDown} />
        </Icon>
      </IconBox>
    </ClickSelectBox>
  );
};
