export const URL_DOMAIN = window && window.env ? window.env.API_URL : "urlApi";
export const PAGE_TITLE =
  (window && window.env && window.env.PAGE_TITLE) || "DeMasterPro";
export const HOLIDAY_URL = window && window.env ? window.env.HOLIDAY_URL : "";
export const cookieExpires = window && window.env && window.env.TOKEN_EXPIRES;
export const USE_GET_USER_FROM_REMOTE =
  window && window.env && window.env.USE_GET_USER_FROM_REMOTE;
export const VEHICLE_ALLOCATION_MANAGEMENT =
  window && window.env ? window.env.VEHICLE_ALLOCATION_MANAGEMENT : false;
export const USER_VEHICLE_IN_REPORT =
  window && window.env ? window.env.USER_VEHICLE_IN_REPORT : false;
export const UNIT_VEHICLE_OUT_REPORT =
  window && window.env ? window.env.UNIT_VEHICLE_OUT_REPORT : false;
/* -------------------------------------- */
export const GLOBAL_STYLE = {
  lightGreenColor: "#e0f2f1",
  greenColor: "#42b883",
  greenForTextColor: "#3baea0",
  lightYellowColor: "#ffebbb",
  yellowColor: "#ffc93c",
  lightRedColor: "#ffcccc",
  redColor: "#f95959",
  darkRedColor: "#B23535",
  lightGrayColor: "#F5F5F5",
  grayColor: "#3A4856",
  shadowedGrayColor: "#34414D",
  darkGrayColor: "#28323C",
  blackColor: "#1C2733",
  orangeColor: "#F4911D",
  lightBlueColor: "#f8fcfb",
  blueColor: "#64ABE1",
  bgBlueColor: "#E6ECF2",
  darkBlueColor: "#1C2953",
  whiteColor: "#fff",
  shadow:
    "0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
  lightShadow:
    "0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 1px -1px rgba(0, 0, 0, 0.12)",
};

export const EXCEPT_SYMBOL_REG_EXP = /[,;|`%\'\"\\<>]/gi;

export const TYPE_ACCOUNT = {
  visitor: -1,
  systemAdmin: 0,
  superAdmin: 1,
  primaryManager: 2,
  secondaryAdmin: 3,
  employee: 5,
};

export const CARD_TYPE = {
  CARD: 0,
  QR: 1,
  PASSCODE: 2,
  NFCPHONE: 3,
  FACEID: 4,
  HFACEID: 5,
  VEHICLE: 6,
  VEIN: 7,
  FINGERPRINT: 8,
  LFACEID: 9,
  BIOFACEID: 10,
  EBKNFACEID: 11,
  EBKNFINGERPRINT: 12,
  ARAREKFINGERPRINT: 13,
};

export const LANGUAGE = {
  ENGLISH: "en-US",
  KOREA: "ko-KR",
  JAPANESE: "ja-JP",
  VIETNAMESE: "vi-VN",
};

export const AVAILABLE_LANGUAGE = Object.keys(LANGUAGE).reduce(
  (acc, cur) => ({ ...acc, [cur]: true }),
  {}
);
export const MQTT_TYPE = {
  NOTIFICATION: { type: "NOTIFICATION", topic: "/topic/notification" },
  NOTIFICATION_RELOAD_COUNT_REVIEW: {
    type: "NOTIFICATION_COUNT_REVIEW",
    topic: "/topic/notification",
  },
  CONNECTION_STATUS: {
    type: "CONNECTION_STATUS",
    topic: "/topic/device_status",
  },
  EVENT_LOG_WEBAPP: {
    type: "EVENT_LOG_WEBAPP",
    topic: "/topic/event_json",
  },
  PROCESS_PROGRESSBAR: {
    type: "LONG_PROCESS_PROGRESS",
    topic: "/topic/long_process_progress",
  },
  LOAD_ALL_USER_WEBAPP: {
    type: "LOAD_ALL_USER_WEBAPP",
    topic: "/topic/load_user_webapp",
  },
  EVENT_COUNT: {
    type: "EVENT_COUNT_WEBAPP",
    topic: "/topic/event_count_json",
  },
  EVENT_BASIC_CHECK_DEVICE: {
    type: "LOAD_DEVICE_SETTING_WEBAPP",
    topic: "/topic/device_setting_webapp",
  },
  EVENT_TIMEZONE_DEVICE_SETTING: {
    type: "LOAD_TIMEZONE_WEBAPP",
    topic: "/topic/load_timezone_webapp",
  },
  EVENT_HOLIDAY_CHECK_DEVICE: {
    type: "LOAD_HOLIDAY_WEBAPP",
    topic: "/topic/load_holiday_webapp",
  },
  NOTIFICATION_CAMERA: {
    type: "NOTIFICATION_CAMERA",
    topic: "/topic/camera",
  },
  VISITOR_CAMERA_INFORMATION: {
    type: "VISITOR_CAMERA_INFORMATION",
    topic: "/topic/camera",
  },
  PAGE_VISITOR_CAMERA_INFORMATION: {
    type: "PAGE_VISITOR_CAMERA_INFORMATION",
    topic: "/topic/camera",
  },
  NOTIFICATION_PROCCESS_CAMERA: {
    type: "NOTIFICATION_PROCCESS_CAMERA",
    topic: "/topic/camera",
  },
  FACE_CAPTURE: {
    type: "FACE_CAPTURE",
    topic: "/topic/face_data",
  },
  VEHICLE_EVENT_LOG_WEBAPP: {
    type: "VEHICLE_EVENT_LOG_WEBAPP",
    topic: "/topic/vehicle_event_json",
  },
  SUB_DISPLAY_DEVICE_INFO: {
    type: "SUB_DISPLAY_DEVICE_INFO",
    topic: "/topic/sub_display/device_info",
  },
  NOTIFICATION_VISITOR_INFO: {
    type: "VISITOR",
    topic: "/topic/visitor_info",
  },
  NOTIFICATION_CAMERA_QRCODE: {
    type: "NOTIFICATION_CAMERA_QRCODE",
    topic: "/topic/camera",
  },
  NOTIFICATION_PROCESS_BOOK: {
    type: "NOTIFICATION_PROCESS_BOOK",
    topic: "/topic/notification/process",
  },
  DEVICE_SCAN_FINGER: {
    type: "DEVICE_SCAN_FINGER",
    topic: "/topic/device_scan_finger",
  },
  DEVICE_SCAN_FINGER_RESPONSE: {
    type: "DEVICE_SCAN_FINGER_RESPONSE",
    topic: "/topic/device_scan_finger_response",
  },
};

/**
 * Object variable for menu names
 * @author WooCheol Kim
 * @date   2020.06.15
 */
export const MENU_NAMES = {
  DashBoard: ["dashBoard"],
  Monitoring: ["monitoring", "deviceMonitoring", "deviceControl"],
  CanteenMonitoring: ["canteenDeviceMonitoring"],
  VisitApplicationManagement: [
    "visitManagement",
    "visitReport",
    "visitSetting",
  ],
  User: ["department", "user"],
  AccessControl: ["accessGroup", "timezone", "holiday"],
  TimeAttendance: ["timeAttendanceReport", "workingTime"],
  Device: ["deviceSetting", "deviceMessage", "building"],
  Report: ["report", "systemLog", "accessibleDoor", "registeredUser"],
  VehicleManagement: "vehicleManagement",
  SystemManagement: "systemManagement",
  CardIssuing: ["cardIssuing", "cardIssuingLayout", "cardIssuingSetting"],
};

export const DATE_FORMAT = {
  "en-US": "MM.dd.yyyy",
  "ko-KR": "yyyy.MM.dd",
  "ja-JP": "yyyy.MM.dd",
  "vi-VN": "dd/MM/yyyy",
};

export const DATE_FORMAT_WITH_HYPHEN = {
  "en-US": "MM-dd-yyyy",
  "ko-KR": "yyyy-MM-dd",
  "ko-JP": "yyyy-MM-dd",
  "vi-VN": "dd-MM-yyyy",
};

export const DATE_TIME_FORMAT = {
  "en-US": "MM.dd.yyyy HH:mm:ss",
  "ko-KR": "yyyy.MM.dd HH:mm:ss",
  "ja-JP": "yyyy.MM.dd HH:mm:ss",
  "vi-VN": "dd/MM/yyyy HH:mm:ss",
};

export const DATE_TIME_FORMAT_WITH_PIPE = {
  "en-US": "MM.dd.yyyy_HH:mm:ss",
  "ko-KR": "yyyy.MM.dd_HH:mm:ss",
  "ja-JP": "yyyy.MM.dd_HH:mm:ss",
  "vi-VN": "dd/MM/yyyy_HH:mm:ss",
};

export const DateTimeFormatDefault = "dd.MM.yyyy HH:mm:ss";

export const NO_CACHE = {
  cacheTime: 0,
};
export const PROCESSSTATUS_INT = {
  waiting: 0,
  approved1: 1,
  approved: 2,
  rejected: 3,
  cardIssued: 4,
  finished: 5,
  finishedWithoutCardReturn: 6,
  cardReturned: 7,
  autoApproved: 8,
};
