import { defineMessages } from "react-intl";

const scope = "demasterpro.containers.App";
export const appMessages = defineMessages({
  confirmAdd: {
    id: `${scope}.confirmAdd`,
    defaultMessage: "추가하시겠습니까?",
  },
  confirmEdit: {
    id: `${scope}.confirmEdit`,
    defaultMessage: "수정하시겠습니까?",
  },
  linkCopied: {
    id: `${scope}.linkCopied`,
    defaultMessage: "링크가 복사 되었습니다.",
  },
  invalidAccess: {
    id: `${scope}.invalidAccess`,
    defaultMessage: "비정상적인 접근입니다. 다시 확인해주세요.",
  },
  invalidData: {
    id: `${scope}.invalidData`,
    defaultMessage: "비정상적인 값입니다. 입력란(들)을 다시 확인해주세요.",
  },
  invalidRequiredData: {
    id: `${scope}.invalidRequiredData`,
    defaultMessage: "Integrated search",
  },
  totalSearch: {
    id: `${scope}.totalSearch`,
    defaultMessage: "Integrated search",
  },
  foldOption: {
    id: `${scope}.foldOption`,
    defaultMessage: "Fold Option",
  },
  showOption: {
    id: `${scope}.showOption`,
    defaultMessage: "Show Option",
  },
  searchPlease: {
    id: `${scope}.searchPlease`,
    defaultMessage:
      "Please set the search conditions and press the search button",
  },
  downloadMobileMsg: {
    id: `${scope}.downloadMobileMsg`,
    defaultMessage:
      "Webapp currently not supported, please download the mobile app!",
  },
  invalidDateFormat: {
    id: `${scope}.invalidDateFormat`,
    defaultMessage: "옳바르지 않은 날짜 형식입니다. (ex - yyyy.MM.dd)",
  },
  invalidTimeFormat: {
    id: `${scope}.invalidTimeFormat`,
    defaultMessage: "옳바르지 않은 시간 형식입니다. (ex - 00:00)",
  },
  morning: {
    id: `${scope}.morning`,
    defaultMessage: "AM",
  },
  afternoon: {
    id: `${scope}.afternoon`,
    defaultMessage: "PM",
  },
  tableReset: {
    id: `${scope}.tableReset`,
    defaultMessage: "테이블 설정 초기화",
  },
  today: {
    id: `${scope}.today`,
    defaultMessage: "오늘",
  },
  lastWeek: {
    id: `${scope}.lastWeek`,
    defaultMessage: "지난 일주일",
  },
  lastMonth: {
    id: `${scope}.lastMonth`,
    defaultMessage: "지난 한 달",
  },
  apply: {
    id: `${scope}.apply`,
    defaultMessage: "적용하기",
  },
  detailSearch: {
    id: `${scope}.detailSearch`,
    defaultMessage: "상세 검색",
  },
  download: {
    id: `${scope}.download`,
    defaultMessage: "다운로드",
  },
  passwordRequired: {
    id: `${scope}.passwordRequired`,
    defaultMessage: "비밀번호는 필수 입력입니다.",
  },
  confirmPasswordRequired: {
    id: `${scope}.confirmPasswordRequired`,
    defaultMessage: "비밀번호 확인은 필수 입력입니다.",
  },
  usernameRequired: {
    id: `${scope}.usernameRequired`,
    defaultMessage: "계정은 필수 입력입니다.",
  },
  visitor: {
    id: `${scope}.visitor`,
    defaultMessage: "방문자",
  },
  systemAdmin: {
    id: `${scope}.systemAdmin`,
    defaultMessage: "시스템 관리자",
  },
  superAdmin: {
    id: `${scope}.superAdmin`,
    defaultMessage: "최고 관리자",
  },
  primaryManager: {
    id: `${scope}.primaryManager`,
    defaultMessage: "주 책임자",
  },
  secondaryAdmin: {
    id: `${scope}.secondaryAdmin`,
    defaultMessage: "부 책임자",
  },
  employee: {
    id: `${scope}.employee`,
    defaultMessage: "직원",
  },
  myProfile: {
    id: `${scope}.myProfile`,
    defaultMessage: "나의 프로필",
  },
  example: {
    id: `${scope}.example`,
    defaultMessage: "샘플",
  },
  required: {
    id: `${scope}.required`,
    defaultMessage: "는 필수 입력사항입니다.",
  },
  btnDismiss: {
    id: `${scope}.btnDismiss`,
    defaultMessage: "확인",
  },
  save: {
    id: `${scope}.save`,
    defaultMessage: "저장",
  },
  confirm: {
    id: `${scope}.confirm`,
    defaultMessage: "확인",
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: "취소",
  },
  search: {
    id: `demasterpro.containers.Common.searchBox`,
    defaultMessage: "찾기",
  },
  titleFormatFile: {
    id: "demasterpro.components.Common.titleFormatFile",
    defaultMessage: "파일 형식",
  },
  unselectAll: {
    id: `demasterpro.components.MultiSelectUI.unselectAll`,
    defaultMessage: "전체 해제",
  },
  selectAll: {
    id: `demasterpro.components.MultiSelectUI.selectAll`,
    defaultMessage: "전체 선택",
  },
});

export const registeredScope = "demasterpro.containers.RegisteredUserPage";

export const RegisterdUserMessages = defineMessages({
  titleTable: {
    id: `${registeredScope}.titleTable`,
    defaultMessage: `User List`,
  },
  placeholderSearchBox: {
    id: `${registeredScope}.placeholderSearchBox`,
    defaultMessage: `Search`,
  },

  //  door
  titleDoorTable: {
    id: `${registeredScope}.titleDoorTable`,
    defaultMessage: `Door List`,
  },
  titleDoorExport: {
    id: `${registeredScope}.titleDoorExport`,
    defaultMessage: `Export Door List`,
  },
  doorName: {
    id: `${registeredScope}.doorName`,
    defaultMessage: `Door Name`,
  },
  deviceAddress: {
    id: `${registeredScope}.deviceAddress`,
    defaultMessage: `Device Address`,
  },
  activeTz: {
    id: `${registeredScope}.activeTz`,
    defaultMessage: `Active Timezone`,
  },
  passageTz: {
    id: `${registeredScope}.passageTz`,
    defaultMessage: `Passage Timezone`,
  },
  verifyMode: {
    id: `${registeredScope}.verifyMode`,
    defaultMessage: `Verify Mode`,
  },
  antiPassback: {
    id: `${registeredScope}.antiPassback`,
    defaultMessage: `anti-Passback`,
  },
  deviceType: {
    id: `${registeredScope}.deviceType`,
    defaultMessage: `Device Type`,
  },
  inOut: {
    id: `${registeredScope}.inOut`,
    defaultMessage: `In/Out`,
  },
  mpr: {
    id: `${registeredScope}.mpr`,
    defaultMessage: `MPR`,
  },

  //  export
  export: {
    id: `${registeredScope}.export`,
    defaultMessage: `Export`,
  },
  titleFormatFile: {
    id: `${registeredScope}.titleFormatFile`,
    defaultMessage: `Format file`,
  },
  cancel: {
    id: `${registeredScope}.cancel`,
    defaultMessage: `cancel`,
  },
  headerIndex: {
    id: `${registeredScope}.headerIndex`,
    defaultMessage: `Index`,
  },
  headerCardId: {
    id: `${registeredScope}.headerCardId`,
    defaultMessage: `Card Id`,
  },
  headerFirstName: {
    id: `${registeredScope}.headerFirstName`,
    defaultMessage: `Name`,
  },
  headerDepartment: {
    id: `${registeredScope}.headerDepartment`,
    defaultMessage: `Department`,
  },
  headerEmployeeNo: {
    id: `${registeredScope}.headerEmployeeNo`,
    defaultMessage: `Employee No`,
  },
  headerExpriedDate: {
    id: `${registeredScope}.headerExpriedDate`,
    defaultMessage: `Expired Date`,
  },
  headerCardStatus: {
    id: `${registeredScope}.headerCardStatus`,
    defaultMessage: `Card Status`,
  },
  firstName: {
    id: "demasterpro.containers.AccessibleDoorPage.firstName",
    defaultMessage: `First name`,
  },
  department: {
    id: "demasterpro.components.DrawerMenu.User.department",
    defaultMessage: `Department`,
  },
  email: {
    id: "demasterpro.containers.UserPage.email",
    defaultMessage: `Email`,
  },
  cardList: {
    id: "demasterpro.containers.DepartmentPage.cardList",
    defaultMessage: `Card list`,
  },
  titleUserToolbarAdd: {
    id: "demasterpro.containers.UserPage.titleUserToolbarAdd",
    defaultMessage: "Add",
  },
  assignUserModalTitle: {
    id: `${registeredScope}.assignUserModalTitle`,
    defaultMessage: `Assign User On Device`,
  },
  unassignedUserListTitle: {
    id: `${registeredScope}.unassignedUserListTitle`,
    defaultMessage: `Unassigned user list`,
  },
  assignedUserTitle: {
    id: `${registeredScope}.assignedUserTitle`,
    defaultMessage: `Assigned user list`,
  },
  titleUserToolBarDelete: {
    id: `demasterpro.containers.UserPage.titleUserToolBarDelete`,
    defaultMessage: `Delete`,
  },
  confirmDeleteUser: {
    id: `demasterpro.containers.UserPage.confirmDeleteUser`,
    defaultMessage: `Do you want to delete the user(s)?`,
  },
  headerPlateNumberList: {
    id: `demasterpro.containers.UserPage.userVehicleList`,
    defaultMessage: `Plate Number`,
  },
});
