import React, { lazy } from "react";
import { Route } from "react-router-dom";

const RegisterUser = lazy(() => import("./User/RegisterUser"));
const SubMonitoringOne = lazy(() => import("./SubMonitoring/SubMonitoringOne"));
const SubMonitoringTwo = lazy(() => import("./SubMonitoring/SubMonitoringTwo"));
const SubMonitoringThree = lazy(() =>
  import("./SubMonitoring/SubMonitoringThree")
);
const RegisterVisit = lazy(() => import("./Visit/RegisterVisit"));
const RegisterVisitMeeting = lazy(() =>
  import("./Meeting/RegisterVisitMeeting")
);
const MeetingRoomDashboard = lazy(() =>
  import("./Meeting/MeetingRoomDashboard")
);

export const CommonRouter = () => {
  return (
    <>
      <Route
        exact
        path="/v1/device-screen-monitoring/:id"
        element={<SubMonitoringOne />}
      />
      <Route
        exact
        path="/v2/device-screen-monitoring/:id"
        element={<SubMonitoringTwo />}
      />
      <Route
        exact
        path="/v3/device-screen-monitoring/:id"
        element={<SubMonitoringThree />}
      />
      <Route
        exact
        path="/v1/register-user/:companyCode/:accountId"
        element={<RegisterUser />}
      />
      <Route
        exact
        path="/v1/register-visit/:companyCode/:accountId"
        element={<RegisterVisit />}
      />
      <Route
        exact
        path="/v1/register-visit-meeting/:companyCode/:meetingId/:visitTargetId"
        element={<RegisterVisitMeeting />}
      />
      <Route
        exact
        path="/v1/meeting-rooms/:meetingRoomId/dashboard"
        element={<MeetingRoomDashboard />}
      />
    </>
  );
};
