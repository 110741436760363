import { errorState, loadingState } from "$utils/atom";
import { LANGUAGE, URL_DOMAIN } from "$utils/constants";
import { getErrorMsg } from "$utils/libs/etcLibs";
import { LogOut } from "$utils/libs/loginLibs";
import { API_HEADER, localstoreUtilites } from "$utils/persistenceData";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { useSetRecoilState } from "recoil";

const lang =
  localstoreUtilites.getLanguageFromLocalStorage() || LANGUAGE.ENGLISH;

export function useFetch(url, queryOptions = {}, fileType = null) {
  const setErrorState = useSetRecoilState(errorState);
  const options = {
    method: "GET",
    ...API_HEADER,
  };
  let httpUrl = url;
  const last = url.slice(-1);
  if (!url.includes("culture=")) {
    if (url.indexOf("?") !== -1) {
      if (last === "&") {
        httpUrl = url.concat(`culture=${lang}`);
      } else {
        httpUrl = url.concat(`&culture=${lang}`);
      }
    } else {
      httpUrl = url.concat(`?culture=${lang}`);
    }
  }
  const setLoadingState = useSetRecoilState(loadingState);
  const key = url;
  const { data, refetch, isLoading, error } = useQuery(
    key,
    () =>
      fetch(`${URL_DOMAIN}${httpUrl}`, options).then((res) => {
        if (!res.ok) {
          if (httpUrl.includes("accounts/avatar")) {
            return;
          }
          if (res.status === 401 || res.status === 403) {
            return LogOut();
          }
          return res.text().then((text) => {
            throw new Error(text);
          });
        }
        if (fileType) {
          const type = "excel" ? "xlsx" : "csv";
          // is IE10+
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(
              new Blob([res.data], { type: res.headers["content-type"] }),
              `Visit_Template.${type}`
            );
          } else {
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(
              new Blob([res.data], { type: res.headers["content-type"] })
            );
            link.download = `Visit_Template.${type}`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
          return res;
        }
        return res.json();
      }),
    {
      retry: 0,
      ...queryOptions,
    }
  );
  useEffect(() => {
    if (isLoading) {
      setLoadingState((prev) => prev + 1);
    } else {
      setLoadingState((prev) => prev - 1);
    }
  }, [isLoading]);
  if (error) {
    const msg = getErrorMsg(error);
    setErrorState(msg);
  }
  return { data, refetch, isLoading };
}
