import React, { useEffect, useState } from "react";
import {
  typeOfApiState,
  ButtonRowWidthState,
  pageModalAtom,
  TooltipTextState,
  fixedMenuState,
  loadingState,
  menuOverlayState,
  mouseLocaleState,
  progressBtnModalState,
  tableFilterState,
  tablePageState,
  tableRowsPerPageState,
  tableSortState,
} from "$utils/atom";
import { useRecoilValue, useResetRecoilState } from "recoil";

export const ResetDataComponent = ({ children, pathname }) => {
  const resetLoadingState = useResetRecoilState(loadingState);
  const resetmenuOverlayState = useResetRecoilState(menuOverlayState);
  const resetmouseLocaleState = useResetRecoilState(mouseLocaleState);
  const resetTooltipTextState = useResetRecoilState(TooltipTextState);
  const resetPageModalState = useResetRecoilState(pageModalAtom);
  const resetButtonRowWidth = useResetRecoilState(ButtonRowWidthState);
  const resetIsFixedMenu = useResetRecoilState(fixedMenuState);
  const resetTypeOfApiState = useResetRecoilState(typeOfApiState);
  const resetProgressModal = useResetRecoilState(progressBtnModalState);
  useEffect(() => {
    return () => {
      resetLoadingState();
      resetmenuOverlayState();
      resetmouseLocaleState();
      resetTooltipTextState();
      resetPageModalState();
      resetTypeOfApiState();
      resetButtonRowWidth();
      resetIsFixedMenu();
      resetProgressModal();
    };
  }, [pathname]);
  return <ResetTableData key={pathname}>{children}</ResetTableData>;
};

const ResetTableData = ({ children }) => {
  const [ready, setReady] = useState(false);
  const tableFilter = useRecoilValue(tableFilterState);
  const tablePage = useRecoilValue(tablePageState);
  const tableRowsPerPage = useRecoilValue(tableRowsPerPageState);
  const tableSort = useRecoilValue(tableSortState);
  const resettablePageState = useResetRecoilState(tablePageState);
  const resettableRowsPerPageState = useResetRecoilState(tableRowsPerPageState);
  const resettableSortState = useResetRecoilState(tableSortState);
  const resettableFilterState = useResetRecoilState(tableFilterState);
  useEffect(() => {
    if (!ready) {
      if (
        Object.keys(tableFilter).length === 0 &&
        Number(tablePage) === 1 &&
        Number(tableRowsPerPage) === 25 &&
        !tableSort
      ) {
        setReady(true);
      } else {
        resettablePageState();
        resettableRowsPerPageState();
        resettableSortState();
        resettableFilterState();
      }
    }
  }, [tableFilter, tablePage, tableRowsPerPage, tableSort]);
  return <>{ready && children}</>;
};
