import { ButtonComponent } from "$components/ButtonComponent";
import {
  ChangeNotificationStatus,
  DeleteNotification,
} from "$components/PrivateRoute/PrivateRouteWrapper/api";
import { SwitchComponent } from "$components/SwitchComponent";
import { GLOBAL_STYLE } from "$utils/constants";
import { countCreatedDuration } from "$utils/libs/timeLibs";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { styled } from "styled-components";

export const EachNotification = ({ item, refetch }) => {
  const [value, setValue] = useState(!item.status);
  useEffect(() => {
    setValue(!item.status);
  }, [item.status]);
  const [onChangeNotificationStatus, { data: result }] =
    ChangeNotificationStatus(item.id);
  const [onDeleteNotification, { data: deleteResult }] = DeleteNotification(
    item.id
  );
  useEffect(() => {
    if (
      (result && result.statusCode) ||
      (deleteResult && deleteResult.statusCode)
    ) {
      refetch();
    }
  }, [result, deleteResult]);
  return (
    <>
      <NotificationContentHead>
        <span>{item.transType}</span>
        <NotificationContentBtns>
          <SwitchComponent
            value={value}
            setValue={(data) => {
              onChangeNotificationStatus({ status: !data });
              setValue(data);
            }}
            id={`notification${item.id}`}
          />
          <ButtonComponent
            icon={
              <FontAwesomeIcon
                style={{
                  fontSize: "18px",
                }}
                color={GLOBAL_STYLE.darkRedColor}
                icon={faTrash}
              />
            }
            onClick={() => {
              onDeleteNotification();
            }}
            contained={false}
            width="43px"
            height="43px"
            circle
          />
        </NotificationContentBtns>
      </NotificationContentHead>
      <NotificationContentBody>
        <span>{item.content}</span>
        <NotificationContentTime>
          {countCreatedDuration(item.createdOn)}
        </NotificationContentTime>
      </NotificationContentBody>
    </>
  );
};
const NotificationContentHead = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 17px;
`;
const NotificationContentBtns = styled.div`
  display: flex;
  gap: 3px;
  align-items: center;
`;
const NotificationContentBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 12px;

  line-height: 17px;
  gap: 5px;
`;
const NotificationContentTime = styled.div`
  font-size: 11px;
  color: gray;
`;
