import React, { useEffect } from "react";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { closeSnackbar, useSnackbar } from "notistack";
import ConfirmSnackbar from "./ConfirmSnackbar";
import { alertMsgState, errorState, responseMsgState } from "$utils/atom";
import { ButtonComponent } from "$components/ButtonComponent";
import { FormattedMessage } from "react-intl";
import { appMessages } from "messages";

/* 
  recoil의 errorState, responseMsgState, alertMsgState 상태 값을 snackbar로 보여주는 컴포넌트
*/

const SnackbarComponent = () => {
  const { enqueueSnackbar } = useSnackbar();
  const errorMsg = useRecoilValue(errorState);
  const responseMsg = useRecoilValue(responseMsgState);
  const alertMsg = useRecoilValue(alertMsgState);
  const resetErrorState = useResetRecoilState(errorState);
  const resetResponseMsgState = useResetRecoilState(responseMsgState);
  const resetAlertMsgState = useResetRecoilState(alertMsgState);
  const action = (snackbarId) => (
    <ButtonComponent
      onClick={() => {
        closeSnackbar(snackbarId);
      }}
      text={<FormattedMessage {...appMessages.confirm} />}
      contained={false}
      width="70px"
      fontSize="13px"
      style={{
        color: "white",
      }}
    />
  );
  useEffect(() => {
    if (responseMsg) {
      enqueueSnackbar(responseMsg.message || responseMsg, {
        variant: responseMsg.variant || "success",
        persist: responseMsg.persist || false,
        autoHideDuration: 3000,
        action,
      });
      resetResponseMsgState();
    }
  }, [responseMsg]);
  useEffect(() => {
    if (errorMsg) {
      enqueueSnackbar(errorMsg, {
        variant: "error",
        autoHideDuration: 5000,
        action,
      });
      resetErrorState();
    }
  }, [errorMsg]);
  return (
    <>
      {alertMsg && (
        <ConfirmSnackbar
          apiMessage={alertMsg.text}
          onResetResult={resetAlertMsgState}
          confirmEvent={alertMsg.action}
          cancelEvent={alertMsg.cancelAction}
          closeEvent={alertMsg.closeAction}
        />
      )}
    </>
  );
};

export default SnackbarComponent;
