import { GLOBAL_STYLE } from "$utils/constants";
import { motion } from "framer-motion";
import React from "react";
import { styled } from "styled-components";

export const SwitchComponent = ({ id, value, setValue, disabled }) => {
  return (
    <>
      <input
        type="checkbox"
        onChange={(event) => {
          setValue(event.target.checked);
        }}
        id={id}
        disabled={disabled}
        checked={value || false}
        style={{ display: "none" }}
      />
      <Box htmlFor={id} value={value}>
        <Circle
          initial={{
            left: value ? "27px" : "2px",
            backgroundColor: value ? GLOBAL_STYLE.lightGrayColor : "lightGray",
          }}
          animate={{
            left: value ? "27px" : "2px",
            backgroundColor: value ? GLOBAL_STYLE.lightGrayColor : "lightGray",
          }}
          transition={{ duration: 0.2 }}
        />
      </Box>
    </>
  );
};
const Box = styled.label`
  width: 50px;
  height: 24px;
  background-color: ${(props) =>
    props.value ? props.theme.grayColor : "lightgray"};
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 13px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
`;
const Circle = styled(motion.div)`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  z-index: 1;
  position: absolute;
  border: 1px solid ${(props) => props.theme.grayColor};
`;
