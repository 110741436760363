import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Overlay } from "$components/Overlay";
import { FormattedMessage } from "react-intl";
import messages from "../messages";
import { ButtonComponent } from "$components/ButtonComponent";
import { motion } from "framer-motion";

export default function ConfirmSnackbar({
  apiMessage,
  onResetResult = () => {},
  confirmEvent,
  cancelEvent,
  closeEvent,
}) {
  const [message, setMessage] = useState("");
  useEffect(() => {
    setMessage(apiMessage);
  }, [apiMessage]);
  const handleClose = () => {
    if (closeEvent) {
      closeEvent();
    }
    setMessage(undefined);
    onResetResult();
  };
  return (
    <Overlay
      zIndex={"2000"}
      open={message !== undefined && message !== ""}
      onClick={(event) => {
        event.stopPropagation();
        handleClose();
      }}
    >
      <Box initial={{ y: -120 }} animate={{ y: 0 }}>
        <Text>{message}</Text>
        <BtnBox>
          <ButtonComponent
            text={<FormattedMessage {...messages.confirm} />}
            onClick={(event) => {
              event.stopPropagation();
              confirmEvent();
              handleClose();
            }}
            width="70px"
            height="35px"
            fontSize="13px"
          />
          <ButtonComponent
            text={<FormattedMessage {...messages.cancel} />}
            onClick={(event) => {
              event.stopPropagation();
              if (cancelEvent) {
                cancelEvent();
              }
              handleClose();
            }}
            contained={false}
            width="70px"
            height="35px"
            fontSize="13px"
          />
        </BtnBox>
      </Box>
    </Overlay>
  );
}
ConfirmSnackbar.propTypes = {
  apiMessage: PropTypes.any,
  onResetResult: PropTypes.func,
  confirmEvent: PropTypes.func,
};
const Box = styled(motion.div)`
  position: fixed;
  top: 30px;
  right: 30px;
  padding: 10px 25px;
  border-radius: 7px;
  display: flex;
  gap: 15px;
  align-items: center;
  color: ${(props) => props.theme.darkGrayColor};
  font-size: 17px;
  background-color: ${(props) => props.theme.lightBlueColor};
  font-weight: bold;
`;
const Text = styled.span`
  font-size: 14px;
`;
const BtnBox = styled.div`
  display: flex;
  gap: 10px;
  margin-left: 5px;
`;
